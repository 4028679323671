import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  GetRegexMaskFromMask,
  GetModifiersFromMask,
  GetStaticCharactersFromMask,
  GetEffectiveAllowNewSelectionListItems,
  AreValuesEqual,
  GetEffectiveSelectionListIsDependent
} from '../Util/Field';

import NoSsr from '@material-ui/core/NoSsr';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
// import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import SelectControl from '../Components/SelectControl'
import NumericTextField from '../Components/NumericTextField'

import { DragSource, DropTarget } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';

import {
  GetTextMatchIsValid,
  GetFieldValuesAsString,
  RequiredFieldStyle,
} from '../Util/Field';
import {
  GetAllFieldsPromise,
  RecurseDependentSelectionListsUpToNonDependentParent
} from '../Util/Fields';
import { BrowserImageMimeTypes } from '../Util/Image';
import CaptureCore from './CaptureCore';
import {
  GetBoolValue,
} from '../Util/Properties';

import debounce from 'es6-promise-debounce';

import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';

/**
 * Implements the drag source contract.
 */
const fieldInputSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props) {
    props.onStartMoveFieldInput();
    return {
      Field: props.Field,
      onDropFieldInput: props.onDropFieldInput,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveFieldInput();
    }
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const fieldInputTarget = {
  drop(props, monitor, component) {
    // if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
    //   return;
    // }

    // Obtain the dragged item
    const fieldInput = monitor.getItem();
    fieldInput.onDropFieldInput();

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    const fieldInput = monitor.getItem();
    const dragRank = fieldInput.Field.FieldRank;
    const hoverRank = props.Field.FieldRank;
    // Don't replace items with themselves
    if (dragRank === hoverRank)
      return;

    props.onMoveFieldInput(fieldInput.Field);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classNames(props.selectProps.classes.input, "fieldInputInput"),
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      style={{
        color:(props.disabled) ? "rgba(0,0,0,0.38)" : null,
      }}
      className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div
    className={classNames(props.selectProps.classes.valueContainer, "fieldInputValueContainer")}
    style={{
      flexWrap:(props.selectProps.isMulti) ? "wrap" : undefined,
    }}
  >{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square
      style={{
        position: "static" // This was added while working on ProcessElementDialog.js
      }} 
      className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const _imageMaxHeight = 200;
const styles = theme => ({
  textField: {
    // margin: theme.spacing(1),
    opacity: 1,
  },
  formControl: {
    // margin: theme.spacing(1),
  },
  input: {
    display: 'flex',
    padding: 0,
    height:'auto',
  },
  valueContainer: {
    display: 'flex',
    // flexWrap: 'wrap', // Necessary for multi-value select - moved to ValueContainer component
    flex: 1,
    alignItems: 'center',
    // The following margin items are necessary for variants outlined/filled
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2) - 3,
    marginLeft: theme.spacing(2) - 2,
    /* These two properties, width:0 and overflow:hidden must remain to fix 
    an issue with the react-select component growing to infinity as users type in the input */
    width: 0,
    overflow: "hidden",
    /* This is a further fix, which prevents the valueContainer from having a large height after a value is in place */
    /* This doesn't appear to be needed now, and, furthermore, it breaks the ability for the height to grow with multi-select fields */ 
    // maxHeight: 27,
  },
  valueContainerMultiSelect: {
    maxHeight: "unset",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    // The following is necessary for variants outlined/filled
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  fieldContainer: {
    display: "flex",
    minHeight: theme.spacing(3),
    width: "100%",
    // padding: theme.spacing(1),
    // margin: -1 * theme.spacing(1),
    borderRadius: theme.spacing(1),
    '&:hover': {
      // backgroundColor: "#eee",
      // border: "1px solid #ccc",
      // margin:-1,
      // borderRadius: 5,
    },
    '&:hover $fieldHandleBox': {
      // backgroundColor: "#aaa",
      cursor: "grab",
      [theme.breakpoints.up('sm')]: {
        visibility:"visible",
      },
    },
  },
  fieldControlBox: {
    flexGrow: 1,
  },
  fieldControlBoxForReadOnly: {
    paddingLeft:6,
    paddingTop:16,
    height:54,
  },
  fieldHandleBox: {
    marginTop:16,
    visibility:"hidden",
    display:"none",
    [theme.breakpoints.up('sm')]: {
      display:"flex",
    },
    justifyContent: "center",
    opacity: 0.4,
  },
  fieldRightMargin: {
    marginRight:theme.spacing(3),
  },
  checkboxContainer: {
    marginTop:8,
    "& .MuiCheckbox-root": {
      alignSelf: "flex-start",
    },
  },
  required: {...RequiredFieldStyle},
  imageBox: {
    position:"relative",
    width: "100%",
    height: _imageMaxHeight,
    textAlign:"center",
  },
  imageBox_NoImage: {
    borderWidth: 1,
    borderStyle: "dashed",
  },
  imageLabel: {
    position:"absolute",
    top:14,
    left:14,
    transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
  },
  imageLabel_Image: {
    top: -10,
    transformOrigin: "top left",
    transform: "scale(0.75)",
  },
  dragLabel: {
    position:"absolute",
    top:"50%",
    bottom:"50%",
    transform:"translate(-50%, -50%)",
    fontStyle:"italic",
  },
});

const placeHolderChar = '\xA0';

// const options = [
//  'Apple',
//  'Apple Banana',
//  'Avacado',
//  'Banana',
//  'Chocolate',
//  'Strawberry',
//  'Vanilla'
// ];
// 
// const filterOptions = (inputValue: string) =>
//   options.filter(v => v.toLowerCase().includes(inputValue.toLowerCase()))
//    .map(v => ({value: v, label: v}));
// 
// const promiseOptions = inputValue =>
//   new Promise(resolve => {
//     setTimeout(() => {
//       resolve(filterOptions(inputValue));
//     }, 500);
//   });

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class FieldInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: (new Date()).toString(),
      ValueChangeUpdateId: new Date(),
    };

    let { Field } = props;

    this.fieldID = `field_${(props.isSecondary) ? "secondary_" : ""}${
      (Field.FieldID !== undefined)
      ? Field.FieldID
      : (Field.ID)
        ? Field.ID
        : Field.Name
      }`; 
    //this.inputRef = React.createRef();
    //this.inputRef = null;

    // this.NumericInput = props => {
    //   const { inputRef, ...other } = props;
    //   let maskConfig = { allowNegative: true, prefix: "" };
    //   let { Field } = this.props;

    //   // Decimal Places
    //   if (typeof Field.DecimalPlaces !== "undefined" && Field.DecimalPlaces != null) {
    //     maskConfig = {...maskConfig, allowDecimal: true, decimalLimit: Field.DecimalPlaces};
    //   }

    //   const numericMask = createNumberMask(maskConfig);
    //   return (
    //     <MaskedInput
    //       {...other}
    //       ref={instance => { inputRef(instance ? instance.inputElement : null);}}
    //       mask={numericMask}
    //       placeholderChar={placeHolderChar}
    //       draggable={!IsMobile()}
    //       onDragStart={e => {
    //         if (!IsMobile()) {
    //           e.preventDefault();
    //           e.stopPropagation();
    //         }
    //       }}
    //     />
    //   );
    // };

    // this.NumericInput.propTypes = {
    //   inputRef: PropTypes.func.isRequired,
    // };

    // this.CurrencyInput = props => {
    //   const { inputRef, ...other } = props;
    //   let maskConfig = { allowNegative: true, prefix: "" };
    //   let { Field } = this.props;

    //   // Currency Prefix
    //   maskConfig = {...maskConfig, prefix: "$"};
    //   Field.DecimalPlaces = 2;

    //   // Decimal Places
    //   maskConfig = {...maskConfig, allowDecimal: true, decimalLimit: 2};

    //   const currencyMask = createNumberMask(maskConfig);
    //   return (
    //     <MaskedInput
    //       {...other}
    //       ref={instance => { inputRef(instance ? instance.inputElement : null);}}
    //       mask={currencyMask}
    //       placeholderChar={placeHolderChar}
    //       draggable={!IsMobile()}
    //       onDragStart={e => {
    //         if (!IsMobile()) {
    //           e.preventDefault();
    //           e.stopPropagation();
    //         }
    //       }}
    //     />
    //   );
    // };

    // this.CurrencyInput.propTypes = {
    //   inputRef: PropTypes.func.isRequired,
    // };

    this.MaskedInput = props => {
      const { inputRef, ...other } = props;
      let { Field } = this.props;

      return (
        <MaskedInput
          {...other}
          autoComplete="off"
          ref={instance => { inputRef(instance ? instance.inputElement : null);}}
          mask={GetRegexMaskFromMask(Field.Mask)}
          pipe={this.ProcessValue}
          placeholderChar={placeHolderChar}
          draggable={!IsMobile()}
          onDragStart={e => {
            if (!IsMobile()) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      );
    }

    this.MaskedInput.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };

    this.beginFileUploadFunc = null;
  }

  // This method should be kept in sync with the expectations of the render method.
  static ShouldRender = (field, fields) => {
    switch (field.Type) {
      case "FieldType_Number":
      case "FieldType_Currency":
      case "FieldType_Text":
      case "FieldType_Date":
      case "FieldType_Bool":
      case "FieldType_Radio":
      case "FieldType_Image":
        break;
      default:
        return false;
    }

    // If the name isn't populated then this isn't an expected field
    if (!field.Name) {
      return true;
    }

    // If this field is null then don't render
    if (!field) {
      return false;
    }

    return FieldInput.AreFieldDependenciesValid(field, fields);
  }

  /**
   * Checks the availability and validity of the fields collection for rendering.
   * 
   * If the fields collection is available, this ensures that all parents are valid to render.
   * If the collection is NOT available, the evaluation will end here with a value of "true". 
   * This is because we lack the means for further verification. In scenarios like form building, 
   * it's deemed appropriate to display the field in the designer without its parent, even if it's temporary.
   */
  static AreFieldDependenciesValid = (field, fields) => {
    if (!fields) {
      return true;
    }

    // If no dependent parent is specified then don't render this control
    if (field.SelectionListIsDependent && !field.ParentFieldID) {
      return false;
    }

    let ret = true;
    RecurseDependentSelectionListsUpToNonDependentParent(field, fields,
      f => (f.FieldID !== undefined) ? f.FieldID : f.ID,
      f => {
        // If we've already decided to not render skip further evaluation
        if (!ret) {
          return;
        }
        // If the current field is null then don't render
        if (!f) {
          ret = false;
          return;
        }

        // If no dependent parent is specified then don't render this control
        if (f.SelectionListIsDependent && !f.ParentFieldID) {
          ret = false;
        }
      }
    );

    return ret;
  }

  ProcessValue = (value, config) => {
    let { Field } = this.props;
    // Modify according to modifier
    let m = GetModifiersFromMask(Field.Mask);
    if (m.length > 0) {
      for (let i = 0; i < m.length; i++) {
        let before = value.substr(0, m[i].position),
          current = value.substr(m[i].position, 1),
          after = value.substr(1 + m[i].position);
        switch (m[i].modifier) {
          case ">":
            value = before + (current + after).toUpperCase();
            break;
          case "<":
            value = before + (current + after).toLowerCase();
            break;
          default:
          value = before + current + after; 
            break;
        }
      }
      // Static characters should always remain static
      let sc = GetStaticCharactersFromMask(Field.Mask);
      if (sc.length > 0) {
        for (let i = 0; i < sc.length; i++) {
          let before = value.substr(0, sc[i].position),
            after = value.substr(1 + sc[i].position);
          value = before + sc[i].character + after; 
        }
      }
    }

    return value;
  }

  handleValueChange = e => {
    let stateToUpdate = {
      ValueChangeUpdateId: new Date()
    };

    // Trim end of masked field values
    // DO NOT USE - This causes issues with masks having static characters 
    // if (this.props.Field.Type === "FieldType_Text" && this.props.Field.Mask) {
    //   e.target.value = e.target.value.trimEnd();
    // }

    // Text Match Type
    if (this.props.Field.TextMatchType) {
      stateToUpdate.InvalidTextMatch = e.target.value.length && !GetTextMatchIsValid(this.props.Field, e.target.value);
    }

    this.setState(stateToUpdate);
    this.props.onValueChange(e);
  }

  handleListSelectionChange = selectedOptions => {
    this.props.onValueChange(null, selectedOptions);
  }

  handleListSelectionAdd = newValue => {
    this.props.onSelectionListItemAdd(newValue);
  }

  handleClearValue = () => {
    this.props.onValueChange("");
  }

  // trySetFocus = () => {
  //  if (this.props.index === 0) {
  //    if (this.inputRef !== null) {
  //      if (typeof this.inputRef.inputElement !== "undefined") {
  //        try {
  //          this.inputRef.inputElement.focus();
  //        }
  //        catch {}
  //      }
  //      else {
  //        try {
  //          this.inputRef.focus();
  //        }
  //        catch {}
  //      }
  //    }
  //  }
  // }

  // Workaround to enable home/end keys on input instead of options
  // https://github.com/JedWatson/react-select/issues/3562
  handleKeyDown = (e, isSelect) => {
    if (isSelect) {
      switch(e.key){
        case "Home":
          e.preventDefault();
          if(e.shiftKey) e.target.selectionStart = 0;
          else e.target.setSelectionRange(0,0);
          break;
        case "End":
          e.preventDefault();
          const len = e.target.value.length;
          if(e.shiftKey) e.target.selectionEnd = len;
          else e.target.setSelectionRange(len,len);
          break;
        default:
          e.stopPropagation(); // Added to prevent form-template fields in the designer from moving when arrow keys are depressed
          break;
      }
    } else {
      e.stopPropagation(); // Added to prevent form-template fields in the designer from moving when arrow keys are depressed
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  handleImageComplete = (reservation, file, resp) => {
    if (resp && resp.data && resp.data.length) {
      const field = resp.data[0];
      this.handleImageChange(field.ImageObjectName, field.ImageSignedUrl);
    }
  }

  handleImageChange = (imageObjectName, imageSignedUrl) => {
    if (this.props.onImageChange) {
      this.props.onImageChange(imageObjectName, imageSignedUrl);
    }
  }

  getFieldsAsync = async (organizationId, projectId) => {
    console.warn('*** WARNING: Fields property not provided; loading all fields manually (expensive)');

    return await GetAllFieldsPromise(organizationId, projectId)
      .then(resp => resp.data.Fields)
      .catch(this.handleApiError);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.Field.FieldID, "Updated");
  }

  async componentDidMount() {
    //console.log(this.props.Field.FieldID, "Mounted");

    //this.trySetFocus();

    const {
      Field,
    } = this.props;

    if (GetEffectiveSelectionListIsDependent(Field) && !Field.AllowMultipleValues) {
      let {
        fields,
      } = this.props;
      if (!fields) {
        fields = await this.getFieldsAsync(Field.OrganizationID, Field.ProjectID);
      }
    }
  }

  handleAsyncSelectValueChange = (currentValue, newValue, actionMeta, handleListSelectionChangeHandler) => {
    if (AreValuesEqual(currentValue, newValue)) {
      return;
    }

    handleListSelectionChangeHandler(newValue, actionMeta);
  }

  render() {
    const { 
      classes,
      theme,
      Field,
      fields,
      Index,
      tabIndex,
      forceAutoFocus,
      LabelPrefix,
      EnableDragAndDropReordering,
      onValueChange,
      onGetSelectionListFilterPromise,
      isDragging,
      disabled,
      parentFieldLacksValue,
      parentFieldName,
      isReadOnly,
      useIndeterminateForFalse,
      forceShrinkLabel,
      forcedPlaceholder,
      forcedBackgroundColor,
      forceAllowClear,
      forceSmallControls,
      disableColorAlerts,
      selectionListOptions,
      hideHelperText,
      redBorderForRequired,
      smallAdornment,
      hideAdornment,
      useSmallFont,
      padRightMarginWhenDragAndDropDisabled,
      //isOver,
      connectDragSource,
      connectDropTarget,
      onApiError,
      onAlert,
      fieldImageUploadReservationUri,
      isInDesigner,
    } = this.props;
    const { 
      fieldID,
      MaskedInput, 
      handleListSelectionChange, 
      handleListSelectionAdd,
    } = this;
    const {
      InvalidTextMatch,
    } = this.state;

    const getSelectStyles = hideIndicators => {
      return {
        input: base => ({
          ...base,
          color: theme.palette.text.primary,
          '& input': {
            font: 'inherit',
          },
        }),
        clearIndicator: base => ({
          ...base,
          cursor: "pointer",
          display:(hideIndicators) ? "none" : "",
        }),
        dropdownIndicator: base => ({
          ...base,
          cursor: "pointer",
          display:(hideIndicators) ? "none" : "",
        }),
        indicatorSeparator: base => ({
          ...base,
          cursor: "pointer",
          display:(hideIndicators) ? "none" : "",
          backgroundColor: "inherit",
        }),
      };
    }

    if (!isInDesigner && !FieldInput.ShouldRender(Field, fields)) {
      return null;
    }

    let helperText = null;
    if (isInDesigner && !FieldInput.AreFieldDependenciesValid(Field, fields)){
      helperText = "Dependencies not setup properly"
      return (<FormHelperText {...this.getDefaultFormHelperTextProps()}>{helperText}</FormHelperText>);
    }

    let formHelperTextProps = {};
    let helperTextComponent = null;
    if (!hideHelperText) {
      if ((Field.Required || Field.RequirementNotMet) && !redBorderForRequired) {
        helperText = "Required";
      }
      if (InvalidTextMatch || (Field.InvalidTextMatch && InvalidTextMatch)) {
        helperText = "Invalid entry";
      }
      if (!disableColorAlerts 
        && (Field.RequirementNotMet || InvalidTextMatch || (Field.InvalidTextMatch && InvalidTextMatch))) {
        formHelperTextProps = this.getDefaultFormHelperTextProps();
      }
      if (helperText) {
        helperTextComponent = (<FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>);
      }
    }
    let requiredClass = null;
    if (Field.Required && redBorderForRequired) {
      requiredClass = classes.required;
    }

    let fieldLabel = (!Field.HideLabel)
      ? (Field.LabelOrName || Field.Label || Field.Name)
      : "";
    if (fieldLabel && LabelPrefix) {
      fieldLabel = LabelPrefix + " " + fieldLabel;
    }

    let autoFocus = (/*!IsMobile() && */ forceAutoFocus || Index === 0);

    const showClearInputAdornment = (Field.Value && Field.Value.length > 0 && !disabled && !hideAdornment);
    const clearInputAdornment = (showClearInputAdornment)
      ? (
        <InputAdornment position="end">
          <IconButton
            tabIndex={-1}
            edge="end"
            size={smallAdornment ? "small" : undefined}
            aria-label="clear"
            onClick={this.handleClearValue}
          >
              <ClearIcon style={{fontSize:18}} />
          </IconButton>
        </InputAdornment>
        ) 
      : null;

    const image = (Field.ImageSignedUrl)
      ? <img src={Field.ImageSignedUrl} alt=""
          style={{paddingTop:theme.spacing(2),height:"100%"}}
      />
      : null;
    const dragLabel = (!image && !disabled)
      ? (
        <Typography variant="caption" className={
          classNames(
            classes.dragLabel,
            "MuiFormLabel-root",
          )
        }>
          Drag and drop image
        </Typography>
      ) : null;
    const imageDiv = (
      <div 
        className={
          classNames(
            classes.imageBox,
            (!image) ? classes.imageBox_NoImage : undefined,
            "MuiOutlinedInput-notchedOutline",
            "MuiOutlinedInput-root",
          )
        }
        onClick={() => { if (!image && this.beginFileUploadFunc) { this.beginFileUploadFunc(); }}}
      >
        <Typography className={
          classNames(
            classes.imageLabel,
            (image) ? classes.imageLabel_Image : undefined,
            "MuiFormLabel-root",
          )
        }>
          {fieldLabel}
        </Typography>
        {dragLabel}
        {image}
      </div>
    );
    // if (image && disabled) {
    //   image = (
    //     <div className={classes.imageBox}>
    //       {image}
    //     </div>
    //   );
    // }

    let fieldControl = null;
    if (isReadOnly || Field.ReadOnly) {
      if (Field.Type === "FieldType_Image" && image) {
        fieldControl = (
          <Grid container direction="column" spacing={0} style={{paddingBottom:theme.spacing(2)}}>
            <Grid item>
              <Typography variant="h6">
                {fieldLabel}
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.imageBox}>
                {image}
              </div>
            </Grid>
          </Grid>
        );
      } else {
        const fieldValuesAsString = GetFieldValuesAsString(Field);
        if (fieldValuesAsString) {
          fieldControl = (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {fieldLabel}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {fieldValuesAsString}
                </Typography>
              </Grid>
            </Grid>
          );
        } else {
          let fieldValues = null;
          if (Field.Type === "FieldType_Bool") {
            fieldValues = GetBoolValue(Field.Value === "true");
          } else if (Field.DisplaySelectionList && Field.AllowMultipleValues) {
            fieldValues = (Field.ListValues) ? Field.ListValues.map(lv => lv.label).join(", ") : null;
          } else {
            fieldValues = Field.Value;
          }
          if (fieldValues) {
            fieldControl = (
              <Grid container direction="column" spacing={0} style={{paddingBottom:theme.spacing(2)}}>
                <Grid item>
                  <Typography variant="h6">
                    {fieldLabel}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {fieldValues}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        }
      }
    } else {
      switch (Field.Type) {
        case "FieldType_Number":
        case "FieldType_Currency":
          fieldControl = (
            <NumericTextField
              id={fieldID}
              label={fieldLabel}
              updateId={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
              autoFocus={autoFocus}
              value={Field.Value}
              onValueChange={onValueChange}
              hideClearButton={!showClearInputAdornment}
              onKeyDown={this.handleKeyDown}
              style={{
                backgroundColor:forcedBackgroundColor,
                opacity: (isDragging) && 0,
              }}
              forceShrinkLabel={forceShrinkLabel || (Field.Value !== undefined && Field.Value.length > 0)}
              disabled={disabled}
              placeholder={forcedPlaceholder}
              inputProps={{
                tabIndex,
                style:{
                  fontSize: (useSmallFont) ? 12 : undefined,
                },
              }}
              helperText={helperText}
              formHelperTextProps={formHelperTextProps}
              // autoComplete="off"
              isCurrency={Field.Type === "FieldType_Currency"}
              decimalPlaces={Field.DecimalPlaces}
              allowNegative
            />
          );
          // fieldControl = (
          //   <FormControl className={classes.formControl} 
          //     variant="outlined"
          //     fullWidth
          //     style={{
          //       backgroundColor:forcedBackgroundColor,
          //       opacity: (isDragging) && 0,
          //     }}>
          //     <InputLabel htmlFor={fieldID}
          //       shrink={forceShrinkLabel || (Field.Value !== undefined && Field.Value.length > 0)}
          //     >
          //       {fieldLabel}
          //     </InputLabel>
          //     <OutlinedInput
          //       autoComplete="off"
          //       label={(forceShrinkLabel || (Field.Value !== undefined && Field.Value.length > 0)) ? fieldLabel : null}
          //       notched={forceShrinkLabel || (fieldLabel && Field.Value !== undefined && Field.Value.length > 0) || false}
          //       key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
          //       autoFocus={autoFocus}
          //       disabled={disabled}
          //       value={Field.Value}
          //       onChange={onValueChange}
          //       id={fieldID}
          //       placeholder={forcedPlaceholder}
          //       inputProps={{ tabIndex }}
          //       inputComponent={(Field.Type === "FieldType_Currency") ? CurrencyInput : NumericInput}
          //       endAdornment={clearInputAdornment}
          //       onKeyDown={this.handleKeyDown}
          //     />
          //     {helperTextComponent}
          //   </FormControl>
          // );
          break;
        case "FieldType_Text":
          if (Field.Mask) {
            fieldControl = (
              <FormControl className={classes.formControl} fullWidth
                variant="outlined"
                style={{
                backgroundColor:forcedBackgroundColor,
                  opacity: (isDragging) && 0,
                }}>
              <InputLabel htmlFor={fieldID} 
                  shrink={forceShrinkLabel || (Field.Value !== undefined && Field.Value.length > 0)}
                >{fieldLabel}</InputLabel>
                <OutlinedInput
                  key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                  autoFocus={autoFocus}
                  label={(forceShrinkLabel || (Field.Value !== undefined && Field.Value.length > 0)) ? fieldLabel : null}
                  notched={forceShrinkLabel || (fieldLabel && Field.Value !== undefined && Field.Value.length > 0) || false}
                  value={Field.Value}
                  disabled={disabled}
                  onChange={this.handleValueChange}
                  id={fieldID}
                  placeholder={forcedPlaceholder}
                  inputComponent={MaskedInput}
                  inputProps={{
                    tabIndex,
                  style:{
                      fontSize: (useSmallFont) ? 12 : undefined,
                    },
                  }}
                  endAdornment={clearInputAdornment}
                  onKeyDown={this.handleKeyDown}
                />
                {helperTextComponent}
              </FormControl>
            );
          }
          else if (Field.DisplaySelectionList) {
            if (GetEffectiveAllowNewSelectionListItems(Field)) {
              fieldControl = (
                <NoSsr>
                  <AsyncCreatableSelect
                    key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                    autoFocus={autoFocus}
                    classes={classes}
                    styles={getSelectStyles(hideAdornment)}
                    textFieldProps={{
                      className: classes.textField,
                      label: fieldLabel,
                      InputLabelProps: {
                        shrink: true,
                      },
                      // TODO: Figure out how to push fontSize through. Using InputProps mangles the AsyncSelect. 
                      // InputProps: {
                      //   style:{
                      //     fontSize: (useSmallFont) ? 12 : undefined,
                      //   },
                      // },
                      style: {
                      backgroundColor:forcedBackgroundColor,
                        opacity: (isDragging) && 0,
                      },
                      helperText: helperText,
                      FormHelperTextProps: formHelperTextProps,
                      draggable: true,
                      onDragStart: e => {
                        e.preventDefault();
                        e.stopPropagation();
                      },
                    }}
                    tabIndex={tabIndex}
                    loadOptions={!disabled && debounce(onGetSelectionListFilterPromise, 250)}
                    defaultOptions={true}
                    cacheOptions={false}
                    components={components}
                    value={Field.ListValues}
                    onChange={(newValue, actionMeta) => this.handleAsyncSelectValueChange(Field.ListValues, newValue, actionMeta, handleListSelectionChange)}
                    onCreateOption={handleListSelectionAdd}
                    hideAdornment={hideAdornment}
                    isClearable={forceAllowClear || !Field.Required}
                    placeholder={(forcedPlaceholder !== undefined) ? forcedPlaceholder : "Select or enter new..."}
                    isDisabled={disabled}
                    formatCreateLabel={inputValue => `${(Field.FormatCreateLabelPrefix) ? Field.FormatCreateLabelPrefix : "Add"} "${inputValue}"`}
                    createOptionPosition="first"
                    isMulti={Field.AllowMultipleValues}
                    // allowCreateWhileLoading can lead to duplicates
                    // allowCreateWhileLoading
                    onKeyDown={event => this.handleKeyDown(event, true)}
                  />
                </NoSsr>
              );
            }
            else {
              const placeholder = forcedPlaceholder ||
                (parentFieldLacksValue ? 'Provide ' + parentFieldName : undefined);

              fieldControl = (
                <NoSsr>
                  <AsyncSelect
                    key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                    autoFocus={autoFocus}
                    classes={classes}
                    styles={getSelectStyles(hideAdornment)}
                    textFieldProps={{
                      className: classes.textField,
                      label: fieldLabel,
                      InputLabelProps: {
                        shrink: true,
                      },
                      // TODO: Figure out how to push fontSize through. Using InputProps mangles the AsyncSelect. 
                      // InputProps: {
                      //   style:{
                      //     fontSize: (useSmallFont) ? 12 : undefined,
                      //   },
                      // },
                      style: {
                      backgroundColor:forcedBackgroundColor,
                        opacity: (isDragging) && 0,
                      },
                      helperText: helperText,
                      placeholder: placeholder,
                      FormHelperTextProps: formHelperTextProps,
                      draggable: true,
                      onDragStart: e => {
                        e.preventDefault();
                        e.stopPropagation();
                      },
                    }}
                    tabIndex={tabIndex}
                    loadOptions={!disabled && debounce(onGetSelectionListFilterPromise, 500)}
                    defaultOptions={true}
                    cacheOptions={false}
                    components={components}
                    value={Field.ListValues}
                    onChange={(newValue, actionMeta) => this.handleAsyncSelectValueChange(Field.ListValues, newValue, actionMeta, handleListSelectionChange)}
                    hideAdornment={hideAdornment}
                    isClearable={forceAllowClear || !Field.Required}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={Field.AllowMultipleValues}
                    onKeyDown={event => this.handleKeyDown(event, true)}
                  />
                </NoSsr>
              );
            }
          }
          else {
            fieldControl = (
              <TextField
                // inputRef={input => (this.inputRef = input)}
                key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
                autoFocus={autoFocus}
                variant="outlined"
                autoComplete="off"
                label={fieldLabel}
                disabled={disabled}
                className={classes.textField}
                value={Field.Value}
                size={(forceSmallControls) ? "small" : undefined}
                multiline={Field.AllowMultipleLines}
                rows={(Field.Rows >= 1) ? Field.Rows : undefined}
                style={{
                backgroundColor:forcedBackgroundColor,
                  opacity: (isDragging) && 0,
                }}
                placeholder={forcedPlaceholder}
                onChange={this.handleValueChange}
                id={fieldID}
                fullWidth
                helperText={helperText}
                FormHelperTextProps={formHelperTextProps}
                draggable
                onDragStart={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onKeyDown={this.handleKeyDown}
                InputProps={{
                  endAdornment: clearInputAdornment,
                }}
                inputProps={{
                  tabIndex,
                style:{
                    fontSize: (useSmallFont) ? 12 : undefined,
                  },
                }}
                InputLabelProps={{ shrink: forceShrinkLabel, }}
              />
            );
          }
          break;
        case "FieldType_Date":
          if (typeof Field.Value !== "undefined" && Field.Value !== null && Field.Value.length > 4) {
            // Ensure inbound year (format yyyy-MM-dd) is not larger than 9999
            try {
              let date = new Date(Field.Value);
              if (date.getFullYear() > 9999)
                Field.Value = "";
            }
            catch { }
          }
          fieldControl = (
            <TextField
              key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
              autoFocus={autoFocus}
              autoComplete="off"
              variant="outlined"
              label={fieldLabel}
              type="date"
              disabled={disabled || Field.UseCreationDate}
              value={Field.Value}
              onChange={onValueChange}
              style={{
              backgroundColor:forcedBackgroundColor,
                opacity: (isDragging) && 0,
              }}
              onKeyDown={this.handleKeyDown}
              id={fieldID}
              className={classes.textField}
              inputProps={{
              max:"9999-12-31",
                tabIndex,
              style:{
                  fontSize: (useSmallFont) ? 12 : undefined,
                paddingTop:(useSmallFont) ? 4 : undefined,
                paddingBottom:(useSmallFont) ? 4 : undefined,
                },
              }}
              InputLabelProps={{ shrink: true/*!IsMobile() this isn't working anymore*/, }}
              InputProps={{
                endAdornment: (!Field.UseCreationDate) ? clearInputAdornment : undefined,
              }}
              placeholder={forcedPlaceholder}
              fullWidth
              helperText={helperText}
              FormHelperTextProps={formHelperTextProps}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          );
          break;
        case "FieldType_Bool":
          fieldControl = (
          <FormControl 
              disabled={disabled}
              onKeyDown={this.handleKeyDown}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    autoFocus={autoFocus}
                    checked={Field.Value === "true"}
                    size="medium"
                    tabIndex={tabIndex}
                    onChange={e => onValueChange(e.target.checked.toString())}
                    id={fieldID}
                    icon={(useIndeterminateForFalse) ? <IndeterminateCheckBoxIcon /> : undefined}
                    name={`f_${fieldID}`} />
                }
                label={
                  <Typography
                    style={{
                      fontSize:(useSmallFont) ? 12 : undefined,
                    }}
                  >
                    {fieldLabel}
                  </Typography>
                }
                style={{
                  marginTop:0,
                  marginRight:0,
                  backgroundColor:forcedBackgroundColor,
                }}
                className={classes.checkboxContainer}
              />
              {helperTextComponent}
            </FormControl>
          );
          break;
        // This is a pseudo type for access to form-template radio groups from outside form templates, such as workflow filters
        case "FieldType_Radio":
          fieldControl = (
            <SelectControl label={fieldLabel}
              id={fieldID}
              hideEmpty
              options={!disabled && selectionListOptions}
              value={Field.Value}
              onValueChange={onValueChange}
            />
          );
          break;
        case "FieldType_Image":
          const getUploadPageImageButton = clickFunc => {
            return (
              <Button variant="contained" fullWidth
                onClick={e => (clickFunc) ? clickFunc(e) : undefined}
              >
                SELECT IMAGE
              </Button>
            );
          }
          const selectButtonGridItem = (
            <Grid item xs={(image) ? 8 : 12}>
              <Button variant="contained" fullWidth
              onClick={() => { if (this.beginFileUploadFunc) { this.beginFileUploadFunc(); }}}
              >
                SELECT IMAGE
              </Button>
            </Grid>
          );
          const clearButtonGridItem = (image)
            ? (
              <Grid item xs={4}>
                <Button variant="contained" fullWidth
                  onClick={() => this.handleImageChange("", "")}
                >
                  CLEAR
                </Button>
              </Grid>
            ) : null;
          fieldControl = (disabled) ? imageDiv : (
            <CaptureCore
              reservationUri={fieldImageUploadReservationUri}
              fullWidth
              singleFile
              skipCompleteAlert
              onSetBeginFileUploadFunc={f => this.beginFileUploadFunc = f}
              onGetChildren={getUploadPageImageButton}
              onComplete={this.handleImageComplete}
              includeResponseInOnComplete
              acceptTypes={BrowserImageMimeTypes}
              onApiError={onApiError}
              onAlert={onAlert}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {imageDiv}
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    {selectButtonGridItem}
                    {clearButtonGridItem}
                  </Grid>
                </Grid>
                {(helperTextComponent)
                  ? (
                  <Grid item style={{marginLeft:13,paddingTop:0,marginTop:-7}}>
                      {helperTextComponent}
                    </Grid>
                  )
                  : undefined
                }
              </Grid>
            </CaptureCore>
          );
          break;
        default:
          return null;
      }
    }

    const noDragAndDropClass = (!EnableDragAndDropReordering && padRightMarginWhenDragAndDropDisabled) ? classes.fieldRightMargin : null;

    const fieldControlBoxForReadOnly = (isReadOnly || Field.ReadOnly) ? classes.fieldControlBoxForReadOnly : null;
    const fieldControlBoxForReadOnlySizing = (isReadOnly || Field.ReadOnly) ? "fieldControlBoxForReadOnlyControlSizing" : null;
    const fieldControlBoxClassNames = classNames(classes.fieldControlBox, fieldControlBoxForReadOnly, 
      fieldControlBoxForReadOnlySizing, requiredClass, noDragAndDropClass);

    return (EnableDragAndDropReordering)
      ? (
        <div className={classes.fieldContainer}
          ref={instance => connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)))}>
          <div className={fieldControlBoxClassNames}>
            {fieldControl}
          </div>
          {/* Handle for drag-and-drop ordering */}
          <div className={classes.fieldHandleBox}
            style={{
                visibility:(isDragging) ? "hidden" : undefined,
            }}
          >
            <DragIndicatorIcon />
          </div>
        </div>
      )
      : (
        <div className={fieldControlBoxClassNames}>
          {fieldControl}
        </div>
      );
  }

  getDefaultFormHelperTextProps = () => {
    return {
      style: {color: red[500]},
    };
  }
}

FieldInput.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  //isOver: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  forceSmallControls: PropTypes.bool,
  smallAdornment: PropTypes.bool,
  hideAdornment: PropTypes.bool,
  padRightMarginWhenDragAndDropDisabled: PropTypes.bool,
  redBorderForRequired: PropTypes.bool,
  useSmallFont: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func,
  fieldImageUploadReservationUri: PropTypes.string,
};

export default DropTarget('FieldInput', fieldInputTarget, dropCollect)(DragSource('FieldInput', fieldInputSource, dragCollect)(withStyles(styles, {withTheme: true})(FieldInput)));
