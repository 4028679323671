import React from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import ItemTableRow from '../Components/ItemTableRow';
import DocumentCard from '../Components/DocumentCard';
import TaskCard from '../Components/TaskCard';
import ApprovalCard from '../Components/ApprovalCard';
import DocumentFolderCard from '../Components/DocumentFolderCard';
import FormTemplateCard from '../Components/FormTemplateCard';
import NeedSignatureCard from '../Components/NeedSignatureCard';
import ProcessCard from '../Admin/Screens/Workflow/ProcessCard';
import FieldCard from '../Components/FieldCard';
import AssetItemCard from '../Components/AssetItemCard';
import OrganizationMemberCard from '../Admin/Components/OrganizationMemberCard';
import OrganizationProjectCard from '../Admin/Components/OrganizationProjectCard';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

import {
  GetPreviewMetadataHeadCells,
  GetPreviewMetadataTableCells,
} from './Metadata';
import {
  OpenDocumentDetailTab,
} from './Documents';
import {
  HandleRouteToDocumentFolder,
} from './DocumentFolders';
import {
	HandleRouteToTask,
} from './Tasks';
import {
  HandleRouteToApprovalDocument,
  HandleRouteToApproval,
} from './Approvals';
import {
  HandleRouteToFormInput,
} from './FormTemplates';
import {
  HandleRouteToOrganizationMember,
} from '../Util/OrganizationMembers';
import {
  HandleRouteToProcessDesigner,
} from '../Util/Processes';
import {
  HandleRouteToProject,
} from '../Util/Projects';
import {
  HandleRouteToFieldDialog,
  AddFilterSortFieldsToHeadCells,
  GetItemTableCellsForFilterSortFields,
} from '../Util/Fields';
import {
  GetTaskLabelByResult,
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
} from '../Util/Task';
import {
  HandleRouteToAssetDialog,
} from '../Util/Assets';
import {
  HandleRouteToAssetItem,
} from '../Util/AssetItems';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';
import {
  GetFormattedHighlight,
} from '../Util/Regex';

export const GetSearchResultsClasses = theme => {
  return {
      searchResultContainer: {
      width: "100%",
    },
    searchResultGroupHeader: {
      marginTop:theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchResult: {
      display:"flex",
      maxHeight: 90,
      overflow: "hidden",
    },
    searchResultThumbnail: {
      minWidth: 64,
      width:64,
      overflow: "hidden",
    },
    searchResultNoThumbnail: {
      minWidth: 64,
      width: 64,
    },
    searchResultThumbnailImage: {
      display:"block",
      width:"calc(100% - 2px)",
      overflow: "hidden",
    },
    searchResultText: {
      // alignSelf: "center",
      marginLeft: theme.spacing(2),
      overflow: "hidden",
    },
    searchResultTextMetadata:{
      display:"flex",
      alignItems:"center",
      marginTop:theme.spacing(0.5),
      fontSize:10,
      whiteSpace:"normal",
      color:theme.palette.text.primary,
      lineHeight:1.5,
    },
    searchResultTextMetadataItem: {
      marginRight: theme.spacing(1),
    },
    searchResultTextMetadataName: {
      fontWeight: 500,
    },
  };
}

const searchResultsHasContentType = (searchResults, contentType) => searchResults
  && searchResults.filter(sr => sr.ContentType === contentType).length;
const hasDocument = searchResults => searchResultsHasContentType(searchResults, "Document");
const hasDocumentFolder = searchResults => searchResultsHasContentType(searchResults, "DocumentFolder");
const hasTask = searchResults => searchResultsHasContentType(searchResults, "Task");
const hasApproval = searchResults => searchResultsHasContentType(searchResults, "Approval");
const hasOrganizationMember = searchResults => searchResultsHasContentType(searchResults, "OrganizationMember");
const hasTags = searchResults => searchResults.filter(sr => sr.Tags.length || sr.AssetItemTags.length).length > 0;
export const GetSearchResultHeadCells = (searchResults, sensitiveFields, simple, advanced, filterSortFields, includeHitHighlight) => {
  let headCells = [];
  if (!advanced) {
    headCells.push(
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    );
    if (!simple || hasTask(searchResults)) {
      headCells.push(
        { id: 'ContentType', sortId: 'Meta_text_kw256lc[ContentType].keyword', numeric: false, label: 'Type' },
        // { id: 'Score', sortId: '_score', numeric: false, label: 'Score' },
        // { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      );
    }
    if (hasTask(searchResults) || hasApproval(searchResults) || (!simple && hasDocument(searchResults)) || hasDocumentFolder(searchResults)) {
      headCells.push(
        { id: 'Assignment', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Assigned to/Shared with' },
      );
    }
    headCells.push(
      { id: 'ProjectName', sortId: 'Meta_text_kw256lc[ProjectName].keyword', numeric: false, label: 'Project' },
    );
    if (searchResults) {
      if (!simple && hasTask(searchResults)) {
        headCells.push(
          { id: 'DueOn', sortId: 'Meta_date_str256[DueOn].string', numeric: false, label: 'Due On' },
          { id: 'Duration', sortId: 'Meta_long[Duration]', numeric: false, label: 'Duration' },
          { id: 'Priority', sortId: 'Meta_int[Priority]', numeric: false, label: 'Priority' },
          { id: 'Milestone', sortId: 'Meta_text_kw256lc[MilestoneName].keyword', numeric: false, label: 'Milestone' },
          { id: 'TaskState', sortId: 'Meta_text_kw256lc[StateName].keyword', numeric: false, label: 'Task State' },
        );
      }
      if (hasTags) {
        headCells.push(
          { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
        );
      }
      
      if (!simple && hasDocument(searchResults)) {
        headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, searchResults));
      }
      if (hasOrganizationMember(searchResults)) {
        headCells.push(
          { id: 'UserEmail', sortId: 'Meta_text_kw256lc[UserEmail].keyword', numeric: false, label: 'Member Email' },
        );
      }
    }
  }

  AddFilterSortFieldsToHeadCells(headCells, filterSortFields);

  if (includeHitHighlight) {
    headCells.push(
      { id: 'HitHighlight', numeric: false, label: 'Hit' },
    );
  }
  
  return headCells;
}

const _cardHeight = 256;
export const GetSearchResultCardGridItems = (props, context, noSelect, noCardActions) =>
  (searchResults, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, cardStyle) => {
  return (searchResults && searchResults.length) 
    ? searchResults
      .map(i => {
    		let card;
    		switch (i.ContentType) {
    			case "Document":
    				card = (
    					<DocumentCard
	              Document={i}
                organizationId={i.OrganizationID}
                projectId={i.ProjectID}
                height={_cardHeight}
                isSearchResults
                allowDownload={i.DocumentOrigin !== "Editor"}
	              sensitiveFields={sensitiveFields}
	              postActionData={postActionData}
	              onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
	              noSelect={noSelect}
	              onSelect={() => onSelect(i.ID)}
	              selected={selectedIDs.indexOf(i.ID) > -1}
	              onAction={(!noCardActions) ? onAction : undefined}
                style={cardStyle}
	            />
  					);
    				break;
          case "DocumentFolder":
            card = (
              <DocumentFolderCard
                DocumentFolder={i}
                history={props.history}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "DocumentSignatureSessionRecipient":
            card = (
              <NeedSignatureCard
                DocumentSignatureSessionRecipient={i}
                organizationId={i.OrganizationID}
                projectId={i.ProjectID}
                height={_cardHeight}
                isSearchResults
                sensitiveFields={sensitiveFields}
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
  				case "Task":
  					card = (
  						<TaskCard
                Task={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
						);
  					break;
          case "Approval":
            card = (
              <ApprovalCard
                Approval={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "FormTemplate":
            card = (
              <FormTemplateCard
                FormTemplate={i}
                history={props.history}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "Process":
            card = (
              <ProcessCard
                Process={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "Field":
            card = (
              <FieldCard
                Field={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "OrganizationMember":
            card = (
              <OrganizationMemberCard
                OrganizationMember={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "Project":
            card = (
              <OrganizationProjectCard
                Project={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
          case "AssetItem":
            card = (
              <AssetItemCard
                AssetItem={i}
                height={_cardHeight}
                isSearchResults
                postActionData={postActionData}
                onCardAction={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
                noSelect={noSelect}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onAction={(!noCardActions) ? onAction : undefined}
              />
            );
            break;
					default:
						break;
    		}
        return (
          <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
            {card}
          </Grid>
        );
      }) 
    : [];
}

export const GetSearchResultTableRows = (props, context, noSelect, simple, advanced, filterSortFields, includeHitHighlight) =>
  (headCells, searchResults, sensitiveFields, classes, theme, onSelect, selectedIDs, sortType, sortDescending) => {

  return (searchResults && searchResults.length)
    ? searchResults
      .map(i => {
        const labelId = `label_${i.ID}`;
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const dueOnDate = new Date(i.DueOn);
        const dueOn = (dueOnDate > new Date("2000-01-01"))
          ? GetDateValue(i.DueOn)
          : "";

        let 
        nameCell,
        tagsCell,
        assignmentCell,
        projectNameCell,
        contentTypeCell,
        organizationMemberCell,
        taskCells = [],
        documentCells = []
        ;
        
        if (!advanced) {
          const name = (i.Name)
            ? i.Name
            : (i.CreatedOn)
              ? GetDateValue(i.CreatedOn)
              : "";
          nameCell = (
            <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none" style={{color:i.DocumentFolderHexColor}}>
              {name}
            </TableCell>
          );

          if (hasTags) {
            let tagsPreview = GetTagsPreviewFromTagsObject(i, 
              sortType === "Meta_text_kw50lc[Tag].keyword" && sortDescending);
            if (tagsPreview.length > 10) {
              tagsPreview = (
                <Tooltip title={tagsPreview}><span>{`${tagsPreview.substr(0, 10)}...`}</span></Tooltip>
              );
            }
            tagsCell = (
              <TableCell key="tc_tags" className={classes.tableCell}>{tagsPreview}</TableCell>
            );
          }

          if (!simple && hasDocument(searchResults)) {
            documentCells.push(...GetPreviewMetadataTableCells(i, headCells, classes));
          }
          
          taskCells = (!simple && hasTask(searchResults))
            ? [
              <TableCell key="tc_dueOn" className={classes.tableCell}>{dueOn}</TableCell>,
              <TableCell key="tc_duration" className={classes.tableCell}>{GetTaskDurationAsString(i.TaskDurationMultiplier, i.TaskDurationInterval)}</TableCell>,
              <TableCell key="tc_priority" className={classes.tableCell}>{GetTaskPriorityLabel(i.Priority)}</TableCell>,
              <TableCell key="tc_milestone" className={classes.tableCell}>{i.TaskMilestoneName}</TableCell>,
              <TableCell key="tc_taskState" className={classes.tableCell}>{i.TaskStateName}</TableCell>,
            ] : null;

          const assignmentValue = (i.AssignmentUserEmails && i.AssignmentUserEmails.length)
            ? i.AssignmentUserEmails.join(", ")
            : GetUserValue(i.AssignmentUserEmail, i.AssignmentUserName);          
          assignmentCell = (hasTask(searchResults) || hasApproval(searchResults)
            || (!simple && hasDocument(searchResults)) || hasDocumentFolder(searchResults))
            ? [
              <TableCell key="tc_assignment" className={classes.tableCell}>{assignmentValue}</TableCell>
            ] : null;

          organizationMemberCell = (hasOrganizationMember(searchResults))
            ? (
              <TableCell key="tc_userEmail" className={classes.tableCell}>{GetUserValue(i.UserEmail, i.UserName)}</TableCell>
            ) : null;

          projectNameCell = (
            <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
          );

          contentTypeCell = (!simple || i.ContentType === "Task") ? (
            <TableCell className={classes.tableCell}>{GetContentTypeLabelFromSearchResult(i, true)}</TableCell>
          ) : null;
        }

        const fieldCells = (filterSortFields)
          ? GetItemTableCellsForFilterSortFields(i, filterSortFields, classes, true, sortType, sortDescending)
          : [];

        let hitHighlightCell = null;
        if (includeHitHighlight) {
          let highlights = [];
          if (i.Highlights && i.Highlights.length) {
            i.Highlights
              .forEach((h, index) => {
                const formattedHighlight = GetFormattedHighlight(h);
                if (highlights.length) {
                  highlights.push(
                    <span key={`s_${index}`}>; {formattedHighlight}</span>  
                  );
                } else {
                  highlights.push(formattedHighlight);
                }
              });
          }
          hitHighlightCell = (highlights)
            ? (
              <TableCell className={classes.tableCell}>{highlights}</TableCell>
            )
            : (
              <TableCell className={classes.tableCell}></TableCell>
            );
        }

        return (
          <ItemTableRow key={`k_${i.ID}`}
            onItemClick={() => HandleRouteToSearchResultItemByContentType(props, context, i, searchResults, sortType, sortDescending)}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                color="secondary"
                style={{
                  visibility: (noSelect) ? "hidden" : undefined,
                }}
                onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
                inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
              />
            </TableCell>
            {nameCell}
            {contentTypeCell}
            {assignmentCell}
            {projectNameCell}
            {/* <TableCell className={classes.tableCell}>{i.Score}</TableCell> */}
            {/* <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell> */}
            {taskCells}
            {tagsCell}
            {documentCells}
            {organizationMemberCell}
            {fieldCells}
            {hitHighlightCell}
          </ItemTableRow>
        );
      }) 
    : [];
}

export const HandleRouteToSearchResultItemByContentType = (props, context, item, allItems, sortType, sortDescending) => {
  const projectMemberPkgFinder = context.ProjectMembershipPackages.filter(p => p.Project.ID === item.ProjectID);
  const isProjectMember = projectMemberPkgFinder.length > 0;
  const isProjectAdmin = isProjectMember && projectMemberPkgFinder[0].ProjectMember.IsAdmin;
  const itemIsAssignedToUser = context.UserPreferences && context.UserPreferences.UserEmail
        && context.UserPreferences.UserEmail === item.AssignmentUserEmail;

  switch (item.ContentType) {
    case "Document":
      const isUserAssignmentContextForDocuments = !isProjectMember
        || (!isProjectAdmin && projectMemberPkgFinder[0].Project.Access.MemberAccess.DocumentFolders === "Assigned");
      OpenDocumentDetailTab(item.OrganizationID, item.ProjectID, item.ID, false,
        { documentFolderID: item.DocumentFolderID }, false,
        (isUserAssignmentContextForDocuments) ? "documentFolder" : undefined);
      break;
    case "DocumentFolder":
      HandleRouteToDocumentFolder(props, item, !itemIsAssignedToUser);
      break;
    case "DocumentSignatureSessionRecipient":
      const routeToSignatureGather = itemIsAssignedToUser;
      OpenDocumentDetailTab(item.OrganizationID, item.ProjectID, item, routeToSignatureGather);
      break;
    case "Task":
      HandleRouteToTask(props, item.ProjectID, item.ID, isProjectMember, false,
        item.Result === "Complete");
      break;
    case "Approval":
      const isUserAssignmentContextForApprovals = !isProjectMember
        || (!isProjectAdmin && projectMemberPkgFinder[0].Project.Access.MemberAccess.Approvals === "Assigned");
      switch (item.ItemType) {
        case "Document":
          HandleRouteToApprovalDocument(item.OrganizationID, item.ProjectID, item.ID, item.ItemID, 
            (isUserAssignmentContextForApprovals), 
            isProjectAdmin || projectMemberPkgFinder[0].Project.Access.MemberAccess.Approvals === "All",
          );
          break;
        case "Task":
        case "AssetItem":
          HandleRouteToApproval(props, item.ProjectID, item.ID, isUserAssignmentContextForApprovals);
          break;
        default:
          console.log("No handler for this ItemType", item.ItemType);
      }
      break;
    case "FormTemplate":
      HandleRouteToFormInput(item.ID, item.UniqueID);
      break;
    case "Process":
      HandleRouteToProcessDesigner(props, item.OrganizationID, item.ProjectID, item.ID);
      break;
    case "Field":
      HandleRouteToFieldDialog(props, item.ProjectID, item.ID);
      break;
    case "OrganizationMember":
      HandleRouteToOrganizationMember(props, item.OrganizationID, item.ID);
      break;
    case "Project":
      HandleRouteToProject(props, item.ID);
      break;
    case "Asset":
      HandleRouteToAssetDialog(props, item.ProjectID, item.ID);
      break;
    case "AssetItem":
      HandleRouteToAssetItem(props, item.ProjectID, item.AssetID, item.ID, item.Archived);
      break;
    default:
      console.log("There is no case for this ContentType", item.ContentType);
      break;
  }
}

export const GetContentTypeLabelFromSearchResult = (item, useSpecificNameWhenAvailable) => {
  return GetContentTypeLabel(item.ContentType, item.Result, item.AssetName,
    item.AssetNamePlural, useSpecificNameWhenAvailable);
}

export const GetContentTypeLabel = (contentType, taskResult, assetName, assetPluralName, useSpecificNameWhenAvailable, plural) => {
  switch (contentType) {
    case "Document":
      return (plural) ? "Documents" : "Document";
    case "DocumentSignatureSessionRecipient":
      return (plural) ? "Documents to Sign": "Document to Sign";
    case "DocumentFolder":
      return (plural) ? "Document Folders" : "Document Folder";
    case "Task":
      return GetTaskLabelByResult(taskResult, plural);
    case "Approval":
      return (plural) ? "Approvals" : "Approval";
    case "FormTemplate":
      return (plural) ? "Forms" : "Form";
    case "Process":
      return (plural) ? "Workflow Processes" : "Workflow Process";
    case "Field":
      return (plural) ? "Fields" : "Field";
    case "OrganizationMember":
      return (plural) ? "Organization Members" : "Organization Member";
    case "Project":
      return (plural) ? "Projects" : "Project";
    case "Asset":
      return (plural) ? "Assets" : "Asset";
    case "AssetItem":
      return (useSpecificNameWhenAvailable && assetName)
        ? ((plural && assetPluralName) ? assetPluralName : assetName)
        : ((plural) ? "Asset items" : "Asset item");
    default:
      console.log("There is no case for this ContentType", contentType);
      return null;
  }
}