import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

import ItemCollectionBase from '../Components/ItemCollectionBase';
import AsyncSelectControl from '../Components/AsyncSelectControl';

import Collection from '../Model/Collection';
import { AdvancedSearchResults_Collection } from '../Model/AdvancedSearchResults';
import {
  GetAdvancedSearchResultsCollectionFieldsByContentType,
} from '../Model/AdvancedSearchResultsCollectionFieldsByContentType';
import { GlobalContext } from '../Context/Global.context';
import ProgressIndicator from '../Components/ProgressIndicator';
import MultiUseDialog from '../Components/MultiUseDialog';

// import { IsMobile } from '../Util/MobileDetector';

import API, {
  GetProjectsPathForApi,
  GetAssetsPathForApi,
  GetAssetFilterSortFieldsPathForApi,
  GetDocumentFilterSortFieldsPathForApi,
  GetOrganizationSearchTemplatesPathForApi,
  GetProjectSearchTemplatesPathForApi,
  GetProjectSearchTemplatePathForApi,
} from '../Util/api';
import {
  GetFilterGridItemsFromFields,
  GetComposedFieldListLabelsAndValues,
} from '../Util/Field';
import {
  GetFilterFieldsAsMetaFieldFilters,
} from '../Util/Filters';
import {
  GetContentTypeLabel,
} from '../Util/SearchResults';
import {
  HandleNext,
  GetStepper,
  GetStepperStyles,
} from '../Util/Stepper';

const styles = theme => ({
  ...GetStepperStyles(theme),
  searchTemplateListValue: {
    display:"flex",
    alignItems:"center",
    minHeight:30,
    width:"100%",
    "&:hover $searchTemplateListValueDeleteIcon": {
      display:"initial",
    },
  },
  searchTemplateListValueDeleteIcon: {
    marginLeft: theme.spacing(2),
    display:"none",
  },
  popoverPaper: {
    padding: theme.spacing(2),
  },
});

const Step_ProjectSelect = 0;
const Step_ContentTypeSelect = 1;
const Step_OptionalCriteria = 2;

class AdvancedSearch extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    const Steps = [
      { index: Step_ProjectSelect,
        getLabel: onGetState => {
          const { SelectedProject, ActiveStepIndex } = onGetState();
          return `Project${(ActiveStepIndex > Step_ProjectSelect && SelectedProject)
            ? ": " + SelectedProject.Name
            : ""}`;
        },
        optional: false,
        // getDescription: () => "Select a project.",
      },
      { index: Step_ContentTypeSelect,
        getLabel: onGetState => {
          const { SelectedContentTypeListValue, ActiveStepIndex } = onGetState();
          return `Content type${(ActiveStepIndex > Step_ContentTypeSelect && SelectedContentTypeListValue) 
            ? ": " + SelectedContentTypeListValue.label
            : ""}`;
        },
        optional: false,
      },
      { index: Step_OptionalCriteria,
        getLabel: onGetState => "Optional criteria",
        optional: true,
        hideOptionalLabel: true,
      },
    ];

    this.initialState = {
      SearchTemplates:[],
      SearchTemplateListUpdateID: null,
      SelectedTemplateListValue: null,
      SearchTemplateToDelete: null,
      Projects: [],
      SelectedProject: null,
      SelectedContentTypeListValue: null,
      SelectedFieldIDs: [],
      StartupMessage: "Choose your search criteria to display results.",
      Assets: [],
      FullText: "",
      Fields: [],
      SecondaryFields: [],
      ActiveStepIndex: 0,
      PopoverText: null,
      BackStep: null,
      CollectionUpdateID: null,
      CompletedGET: {},
      ShowSaveTemplateDialog: false,
      ShowUpdateTemplateDialog: false,
      ShowDeleteTemplateDialog: false,
      ShowFieldsSelectDialog: false,
      ShowProgressIndicatorImmediately: false,
    };

    this.state = {
      ...this.initialState,
      Steps,
    }

    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
    this.LoadItems = () => {};
    this.LeftPaneContentRef = React.createRef();
  }

  connectRefreshItemsFunction = f => {
    this.LoadItems = f;
  }

  setCollectionWhenReady = () => {
    if (!this.context.CompletedGET.UserPreferences 
      || !this.context.CompletedGET.ProjectMembershipPackages
      || !this.state.SelectedProject
      || (this.state.SelectedContentTypeListValue &&
          (this.state.SelectedContentTypeListValue.value.ContentType === "Document"
            || this.state.SelectedContentTypeListValue.value.ContentType === "AssetItem")
          && !this.state.CompletedGET.Fields
        )
    ) {
      setTimeout(() => this.setCollectionWhenReady(), 250);
      return;
    }
    
    this.Collection = new AdvancedSearchResults_Collection(this.props, this.context, 
      state => this.setState(state), this.handleApiError,
      this.context.UserPreferences.ActiveOrganizationID,
      this.state.SelectedProject.ID,
      this.state.Fields);
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  componentDidMount() {
    // For testing
    // let templateJson = `{"ProjectID":"XBFmmoTPJfUi29trIEJp","ContentType":"AssetItem","ContentTypeAssetID":"ou9Ut15V9YjQskYwCNPv","FullText":"Joey","Fields":[{"ID":"IxF_Text[xIklmEvyWlOVjXft0pmV]","Operator":"startsWith","Value":"ABC125","Values":""},{"ID":"IxF_Date[JVoLLVBROlWpY7RpeOYH]","Operator":"betweenAndIncluding","Value":"2000-05-10","Values":""}],"SecondaryFields":[{"ID":"IxF_Text[xIklmEvyWlOVjXft0pmV]","Value":"","Values":[]},{"ID":"IxF_Date[JVoLLVBROlWpY7RpeOYH]","Value":"2000-05-10","Values":""}],"SelectedFieldIDs":["IxF_Text[xIklmEvyWlOVjXft0pmV]","IxF_Date[JVoLLVBROlWpY7RpeOYH]"]}`;
    // this.handleParseTemplateJson(templateJson);
  }

  componentDidUpdate(prevProps, prevState) {
    const projectChanged = (
      this.state.SelectedProject !== prevState.SelectedProject
      ||
      (this.state.SelectedProject && prevState.SelectedProject
        && this.state.SelectedProject.ID !== prevState.SelectedProject.ID)
    );

    const contentTypeChanged = (
      this.state.SelectedContentTypeListValue !== prevState.SelectedContentTypeListValue
      ||
      (this.state.SelectedContentTypeListValue && prevState.SelectedContentTypeListValue
        && this.state.SelectedContentTypeListValue.value.ContentType
          !== prevState.SelectedContentTypeListValue.value.ContentType)
    ); 

    if (projectChanged || contentTypeChanged) {
      this.setCollectionWhenReady();
    }
  }

  userIsOrgAdmin = () => {
    const {
      CompletedGET,
      UserPreferences,
      OrganizationMembershipPackages,
    } = this.context;
    let isOrgAdmin = false;
    if (CompletedGET.UserPreferences && CompletedGET.OrganizationMembershipPackages) {
      const orgPkg = OrganizationMembershipPackages
        .find(omp => omp.Organization.ID === UserPreferences.ActiveOrganizationID);
      if (orgPkg) {
        isOrgAdmin = orgPkg.IsAdmin;
      }
    }
    return isOrgAdmin;
  }

  handleApiError = err => {
    this.setState({
      ApiError:err,
      ShowProgressIndicatorImmediately: false,
    });
    if (err) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  handleSetShowSaveTemplateDialog = ShowSaveTemplateDialog => {
    this.setState({ShowSaveTemplateDialog});
  }

  handleSetShowUpdateTemplateDialog = ShowUpdateTemplateDialog => {
    this.setState({ShowUpdateTemplateDialog});
  }

  handleSetShowDeleteTemplateDialog = (ShowDeleteTemplateDialog, SearchTemplateToDelete) => {
    this.setState({
      ShowDeleteTemplateDialog,
      SearchTemplateToDelete,
    });
  }

  handleSetShowFieldsSelectDialog = ShowFieldsSelectDialog => {
    this.setState({ShowFieldsSelectDialog});
  }

  getListValueForSearchTemplate = searchTemplate => {
    return {
      name: searchTemplate.Name,
      label: (this.userIsOrgAdmin()) ? (
        <div className={this.props.classes.searchTemplateListValue}>
          <div style={{flexGrow:1}}>{searchTemplate.Name}</div>
          <Tooltip title="Delete template">
            <IconButton
              size="small"
              className={this.props.classes.searchTemplateListValueDeleteIcon}
              onClick={e => {
                e.stopPropagation();
                this.handleSetShowDeleteTemplateDialog(true, searchTemplate);
              }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : searchTemplate.Name,
      value:searchTemplate.ID,
    };
  }

  handleGetSearchTemplateOptions = nameFilter => {
    return API.get(GetOrganizationSearchTemplatesPathForApi(
      this.context.UserPreferences.ActiveOrganizationID), { params: { nameFilter }})
      .then(resp => {
        const SearchTemplates = resp.data.SearchTemplates;
        let stateToUpdate = {
          SearchTemplates,
        };
        // Update selected item label if it's in the list
        if (this.state.SelectedTemplateListValue) {
          const selectedSearchTemplate = SearchTemplates
            .find(st => st.ID === this.state.SelectedTemplateListValue.value);
            if (selectedSearchTemplate) {
              stateToUpdate.SelectedTemplateListValue = this.getListValueForSearchTemplate(selectedSearchTemplate);
            }
        }
        this.setState(stateToUpdate);
        return SearchTemplates.map(st => this.getListValueForSearchTemplate(st));
      })
      .catch(this.handleApiError);
  }

  getListValueForProject = project => {
    return {label:project.Name,value:project};
  }

  getListValueForContentType = (ContentType, AssetID, AssetName) => {
    return {
      label:AssetName || GetContentTypeLabel(ContentType, null, AssetName, null, true),
      value:{
        AssetID,
        AssetName,
        ContentType,
      },
    };
  }

  handleGetAssets = project => {
    if (!project) {
      project = this.state.SelectedProject;
    }

    this.setState({ShowProgressIndicatorImmediately: true});

    return API.get(GetAssetsPathForApi(this.context.UserPreferences.ActiveOrganizationID, project.ID),
      { params: { getAll: true }})
      .then(resp => {
        const Assets = resp.data.Assets;
        this.setState({ Assets });
        return Assets;
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      });
  }

  compareFields = (a, b) => {
    if (a.LabelOrName < b.LabelOrName) {
      return -1;
    }
  };

  isFieldSelected = field => Boolean(this.state.SelectedFieldIDs.find(fieldId => fieldId === field.ID))

  getNormalizedFieldsAndSecondaryFields = (filterAndSortFields, contentType) => {
    let Fields = this.getStaticFilterSortFieldsByContentType(contentType);
    Fields.push(...filterAndSortFields
      .filter(f => f.Field.Type !== "FieldType_Image")
      .map(f => {
        return {
          ...f.Field,
          // FieldID is used for gathering selection list items since ID will be used for the Elasticsearch ID
          FieldID: f.Field.ID,
          ID: f.FilterID,
          FilterID: f.FilterID,
          SortID: f.SortID,
          // Don't allow new selection-list items in this context
          AllowNewSelectionListItems: false,
          // No need for requirements in this context
          Required: false,
          Value: "",
          Values: "",
        };
      })      
      .sort(this.compareFields)
    );

    if (contentType === "AssetItem") {
      Fields = Fields.sort(this.compareFields);
    }

    return {
      Fields,
      SecondaryFields: JSON.parse(JSON.stringify(Fields)),
    };
  }

  handleGetDocumentFields = project => {
    if (!project) {
      project = this.state.SelectedProject;
    }

    this.setState({
      CompletedGET: { ...this.state.CompletedGET, Fields: false },
      ShowProgressIndicatorImmediately: true,
    });

    return API.get(GetDocumentFilterSortFieldsPathForApi(this.context.UserPreferences.ActiveOrganizationID, project.ID),
      { params: { getAll: true }})
      .then(resp => {
        const {
          Fields,
          SecondaryFields,
        } = this.getNormalizedFieldsAndSecondaryFields(resp.data, "Document");
        this.setState({
          Fields,
          SecondaryFields,
          CompletedGET: { ...this.state.CompletedGET, Fields: true },
        });
        return { Fields, SecondaryFields };
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ ShowProgressIndicatorImmediately: false });
      });
  }

  handleGetAssetItemFields = (project, assetId) => {
    if (!assetId) {
      return;
    }
    if (!project) {
      project = this.state.SelectedProject;
    }

    this.setState({
      CompletedGET: { ...this.state.CompletedGET, Fields: false },
      ShowProgressIndicatorImmediately: true,
    });

    return API.get(GetAssetFilterSortFieldsPathForApi(this.context.UserPreferences.ActiveOrganizationID, project.ID, assetId))
      .then(resp => {
        const {
          Fields,
          SecondaryFields,
        } = this.getNormalizedFieldsAndSecondaryFields(resp.data, "AssetItem");
        this.setState({
          Fields,
          SecondaryFields,
          CompletedGET: { ...this.state.CompletedGET, Fields: true },
        });
        return { Fields, SecondaryFields };
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      });
  }

  handleGetContentTypeOptions = assets => {
    if (!assets) {
      assets = this.state.Assets;
    }
    const contentTypeOptions = [
      this.getListValueForContentType("Document"),
      this.getListValueForContentType("FormTemplate"),
      this.getListValueForContentType("Task"),
    ];
    if (assets && assets.length) {
      contentTypeOptions.push(
        {divider: true},
        ...assets.map(a => this.getListValueForContentType("AssetItem", a.ID, a.Name))
      )
    }
    return contentTypeOptions;
  }

  handleGetProjectOptions = (nameFilter, includeProjectId) => {
    if (!includeProjectId && this.state.SelectedProject) {
      includeProjectId = this.state.SelectedProjectID;
    }

    return API.get(GetProjectsPathForApi(this.context.UserPreferences.ActiveOrganizationID), 
      { params: {
        nameFilter,
        includeProjectId,
      }},
    )
      .then(resp => {
        return resp.data.Projects.map(p => this.getListValueForProject(p));
      })
      .catch(this.handleApiError);
  }

  getStaticFilterSortFieldsByContentType = contentType => {
    return GetAdvancedSearchResultsCollectionFieldsByContentType(contentType)
      .map(f => ({
        ...f,
        SortID: f.ID,
        ID: f.FilterID || f.ID,
      }));
  }

  handleSelectItem = (propName, useFullListValue, advance) => async selectedOption => {
    const value = (selectedOption && selectedOption.value) || null;
    
    // Load items for the next step, if applicable
    switch (propName) {
    case "SelectedTemplateListValue":
      if (value) {
        const SearchTemplates = [...this.state.SearchTemplates];
        const template = SearchTemplates.find(st => st.ID === value);
        if (template) {
          this.handleParseTemplateJson(template.TemplateJson);
        }
      } else {
        this.setState({
          SelectedProject: null,
          SelectedContentTypeListValue: null,
          ActiveStepIndex: Step_ProjectSelect,
          Steps: [...this.state.Steps].map(step => ({...step,completed:false})),
        });
      }
      break;
    case "SelectedProject":
      this.setState({SelectedContentTypeListValue: null});
      if (value) {
        await this.handleGetAssets(value);
      }
      break;
    case "SelectedContentTypeListValue":
      let stateToUpdate = {
        FullText: "",
        SelectedFieldIDs: [],
      };
      if (value) {
        switch (value.ContentType) {
        case "AssetItem":
          await this.handleGetAssetItemFields(this.state.SelectedProject, value.AssetID);
          break;
        case "Document":
          await this.handleGetDocumentFields();
          break;
        case "Task":
        case "FormTemplate":
          stateToUpdate.Fields = this.getStaticFilterSortFieldsByContentType(value.ContentType);
          stateToUpdate.SecondaryFields = JSON.parse(JSON.stringify(stateToUpdate.Fields));
          break;
        default:
          stateToUpdate.Fields = [];
          break;
        }
      }
      this.setState(stateToUpdate);
      break;
    default:
      break;
    }
    let stateToUpdate = {
      [propName]: (useFullListValue) ? selectedOption : value,
    }
    // let newSteps = [...this.state.Steps];
    // stateToUpdate.Steps = newSteps;
    this.setState(stateToUpdate);
    if (value && advance) {
      HandleNext(() => this.state, state => this.setState(state), this.handleFinish, null, false);
    }
  }

  handleSelectItems = propName => async selectedOptions => {
    this.setState({[propName]: selectedOptions});
  }

  handleInvertFieldSelected = field => {
    let SelectedFieldIDs = [...this.state.SelectedFieldIDs];
    let selectedField = SelectedFieldIDs.find(fieldId => field.ID === fieldId);
    if (selectedField) {
      SelectedFieldIDs = SelectedFieldIDs.filter(fieldId => fieldId !== field.ID);
    } else {
      SelectedFieldIDs.push(field.ID)
    }
    this.setState({SelectedFieldIDs});
  }

  handleUpdateFieldsState = state => {
    let stateToUpdate = {};
    if (state.Fields) {
      let Fields = [...this.state.Fields];
      state.Fields.forEach(updatedField => {
        const field = Fields.find(f => f.ID === updatedField.ID);
        if (field) {
          field.Value = updatedField.Value;
          field.Values = updatedField.Values;
        }
      });
      stateToUpdate.Fields = Fields;
    }
    if (state.SecondaryFields) {
      let SecondaryFields = [...this.state.SecondaryFields];
      state.SecondaryFields.forEach(updatedField => {
        const field = SecondaryFields.find(f => f.ID === updatedField.ID);
        if (field) {
          field.Value = updatedField.Value;
          field.Values = updatedField.Values;
        }
      });
      stateToUpdate.SecondaryFields = SecondaryFields;
    }
    this.setState(stateToUpdate);
  }

  handleFieldValueKeyDown = (field_optional, e) => {
    if (field_optional && field_optional.DisplaySelectionList) {
      return;
    }
    if (e && e.key === "Enter") {
      this.handleFinish();
    }
  }

  handleSkip = () => {
    const onGetState = () => this.state;
    const onSetState = state => this.setState(state);
    switch (this.state.ActiveStepIndex) {
      // case Step_OptionalCriteria:
      //   HandleNext(onGetState, onSetState, this.handleFinish, null, true, {
      //     Fields: [],
      //   });
      //   break;
      default:
        HandleNext(onGetState, onSetState, this.handleFinish, null, true);
        break;
    }
  }

  handleMove = stepIndex => {
    const {
      Steps,
    } = this.state;

    Steps
      .filter(s => s.index >= stepIndex)
      .forEach(s => s.completed = false);

    let stateToUpdate = {
      Steps,
      ActiveStepIndex: stepIndex,
    };

    switch (stepIndex) {
      case Step_ProjectSelect:
        stateToUpdate.SelectedProject = null;
        break;
      case Step_ContentTypeSelect:
        stateToUpdate.SelectedContentTypeListValue = null;
        break;
      default:
        break;
    }

    this.setState(stateToUpdate);
  }

  handleFinish = () => {
    this.LoadItems(true);
  }

  handleShowPopover = PopoverText => {
    this.setState({ PopoverText });
    setTimeout(() => this.setState({ PopoverText: null }), 1500); 
  }

  handleParseTemplateJson = templateJson => {
    this.setState({ShowProgressIndicatorImmediately:true});

    let searchTemplate;
    try {
      searchTemplate = JSON.parse(templateJson);
    }
    catch(err) {
      console.log(err);
      this.handleApiError("The selected template is not valid.");
    }

    // SelectedProject
    if (!searchTemplate.ProjectID) {
      return this.handleApiError("Project is required.")
    }
    
    let stateToUpdate = {};

    this.handleGetProjectOptions(null, searchTemplate.ProjectID)
      .then(projectOptions => {
        let selectedProjectListValue = projectOptions
          .find(lv => lv.value.ID === searchTemplate.ProjectID);
        if (!selectedProjectListValue) {
          return this.handleApiError("You do not have access to the selected project.");
        }
        const SelectedProject = selectedProjectListValue.value;
        stateToUpdate.SelectedProject = SelectedProject;
        
        // SelectedContentTypeListValue
        this.handleGetAssets(selectedProjectListValue.value)
          .then(async assets => {
            const contentTypeOptions = this.handleGetContentTypeOptions(assets);
            let SelectedContentTypeListValue = contentTypeOptions
              .find(o => o.value && o.value.ContentType === searchTemplate.ContentType
                && o.value.AssetID === searchTemplate.ContentTypeAssetID);
            if (!SelectedContentTypeListValue) {
              return this.handleApiError("Selected content type is invalid.");
            }
              
            stateToUpdate.SelectedContentTypeListValue = SelectedContentTypeListValue;

            // SelectedFieldIDs, Fields, SecondaryFields, FullText
            let Fields, SecondaryFields;
            switch (SelectedContentTypeListValue.value.ContentType) {
            case "AssetItem":
              const assetItemFieldsResult = await this.handleGetAssetItemFields(SelectedProject, 
                SelectedContentTypeListValue.value.AssetID);
              Fields = assetItemFieldsResult.Fields;
              SecondaryFields = assetItemFieldsResult.SecondaryFields;
              break;
            case "Document":
              const documentFieldsResult = await this.handleGetDocumentFields(SelectedProject);
              Fields = documentFieldsResult.Fields;
              SecondaryFields = documentFieldsResult.SecondaryFields;
              break;
            case "Task":
            case "FormTemplate":
              Fields = this.getStaticFilterSortFieldsByContentType(
                SelectedContentTypeListValue.value.ContentType);
              SecondaryFields = JSON.parse(JSON.stringify(Fields));
              break;
            default:
              Fields = [];
              SecondaryFields = [];
              break;
            }
            const updateFields = (fields, propName) => fields.forEach(field => {
              const savedField = searchTemplate[propName].find(f => f.ID === field.ID);
              if (savedField) {
                field.HasValue = Boolean(savedField.Value) || Boolean(savedField.Values && JSON.parse(savedField.Values).length);
                field.Value = savedField.Value;
                field.Values = savedField.Values;
                field.ListValues = GetComposedFieldListLabelsAndValues(field);
                field.Operator = savedField.Operator;
              }
            });
            updateFields(Fields, "Fields");
            const atLeastOneFieldHasValue = Boolean(Fields.filter(f => f.HasValue).length);
            updateFields(SecondaryFields, "SecondaryFields");
            this.setState({
              ...stateToUpdate,
              SelectedFieldIDs: searchTemplate.SelectedFieldIDs,
              Fields,
              SecondaryFields,
              FullText: searchTemplate.FullText,
              ActiveStepIndex: Step_OptionalCriteria,
              Steps: [...this.state.Steps].map(step => ({...step,completed:step.index !== Step_OptionalCriteria})),
            });

            if (atLeastOneFieldHasValue || searchTemplate.FullText) {
              setTimeout(() => this.handleFinish(), 100);
            }
          });
      });
  }

  handleSaveAsTemplate = (Name, isUpdate) => {
    if (!Name) {
      return;
    }

    if (isUpdate) {
      this.handleSetShowUpdateTemplateDialog(false);
    } else {
      this.handleSetShowSaveTemplateDialog(false);
    }

    const getFieldForTemplate = f => {
      return {
        ID: f.ID,
        Operator: f.Operator,
        Value: f.Value,
        Values: f.Values,
      };
    }
    const searchTemplate = {
      ProjectID: this.state.SelectedProject.ID,
      ContentType: this.state.SelectedContentTypeListValue.value.ContentType,
      ContentTypeAssetID: this.state.SelectedContentTypeListValue.value.AssetID,
      FullText: this.state.FullText,
      Fields: this.state.Fields
        .filter(f => this.isFieldSelected(f))
        .map(f => getFieldForTemplate(f)),
      SecondaryFields: this.state.SecondaryFields
        .filter(f => this.isFieldSelected(f))
        .map(f => getFieldForTemplate(f)),
      SelectedFieldIDs: this.state.SelectedFieldIDs,
    };

    const SearchTemplate = {
      Name,
      TemplateJson: JSON.stringify(searchTemplate),
    };

    if (isUpdate) {
      API.put(GetProjectSearchTemplatePathForApi(this.context.UserPreferences.ActiveOrganizationID,
        this.state.SelectedProject.ID,
        this.state.SelectedTemplateListValue.value), SearchTemplate)
        .then(resp => {
          this.handleShowPopover("Template updated!");
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({SearchTemplateListUpdateID:new Date()});
        });
    } else {
      API.post(GetProjectSearchTemplatesPathForApi(this.context.UserPreferences.ActiveOrganizationID,
        this.state.SelectedProject.ID), [SearchTemplate])
        .then(resp => {
          this.setState({SelectedTemplateListValue: this.getListValueForSearchTemplate(resp.data[0])});
          this.handleShowPopover("Template created!");
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({SearchTemplateListUpdateID:new Date()});
        });
    }
  }

  handleDeleteTemplate = () => {
    if (!this.state.SearchTemplateToDelete) {
      return;
    }
    this.handleSetShowDeleteTemplateDialog(false);

    API.delete(GetProjectSearchTemplatesPathForApi(this.context.UserPreferences.ActiveOrganizationID),
      { data: { IDs: [this.state.SearchTemplateToDelete.ID] }})
      .then(resp => {
        this.handleShowPopover("Template deleted!");
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({
          SearchTemplateToDelete: null,
          SelectedTemplateListValue: null,
          SearchTemplateListUpdateID:new Date(),
        });
      });
  }

  render() {
    const {
      // Steps,
      SearchTemplates,
      SearchTemplateListUpdateID,
      SelectedTemplateListValue,
      SearchTemplateToDelete,
      ActiveStepIndex,
      SelectedProject,
      SelectedContentTypeListValue,
      Fields,
      SecondaryFields,
      StartupMessage,
      FullText,
      ApiError,
      Alert,
      PopoverText,
      ShowFieldsSelectDialog,
      ShowSaveTemplateDialog,
      ShowUpdateTemplateDialog,
      ShowDeleteTemplateDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      UserPreferences,
    } = this.context;
    const {
      classes,
      theme,
      ...restProps
    } = this.props;

    const organizationId = UserPreferences.ActiveOrganizationID;

    if (!organizationId) {
      return null;
    }

    const templateList = (
      <AsyncSelectControl
        // label={(SelectedTemplateListValue) ? "Template" : undefined}
        compact
        noFlexWrap
        placeholder="Select a template..."
        floatingOptions
        onGetOptionsFilterPromise={filter => this.handleGetSearchTemplateOptions(filter)}
        value={SelectedTemplateListValue}
        onValueChange={this.handleSelectItem("SelectedTemplateListValue", true)}
        updateId={SearchTemplateListUpdateID}
      />
    );
    const templateListDiv = (
      <div style={{
        marginBottom: theme.spacing(3),
        display: (!SearchTemplates || !SearchTemplates.length) ? "none" : undefined,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3),
      }}>
        {templateList}
      </div>
    );

    const projectContent = (
      <AsyncSelectControl
        // label="Project"
        autoFocus
        floatingOptions
        onGetOptionsFilterPromise={filter => this.handleGetProjectOptions(filter)}
        value={SelectedProject && this.getListValueForProject(SelectedProject)}
        onValueChange={this.handleSelectItem("SelectedProject", false, true)}
      />
    );

    const handleGetContentTypeOptions = filter => {
      return Promise.resolve(
        this.handleGetContentTypeOptions()
          .filter(o => !filter || o.label.toLowerCase().startsWith(filter.toLowerCase()))
      );
    };
    const contentTypeContent = (
      <AsyncSelectControl
        // label="Content Type"
        autoFocus
        floatingOptions
        onGetOptionsFilterPromise={handleGetContentTypeOptions}
        value={SelectedContentTypeListValue}
        onValueChange={this.handleSelectItem("SelectedContentTypeListValue", true, true)}
      />
    );

    let optionalCriteriaContent;
    let fieldSelectDialog;
    let fieldGridItems;
    let fieldsSelectButton;
    if (ActiveStepIndex === Step_OptionalCriteria) {
      if (Fields && Fields.length) {
        const fieldSelectControl = (
          <List>
            {
              Fields.map(field => {
                return (
                  <ListItem key={`li_${field.ID}`}
                    button
                    selected={this.isFieldSelected(field)}
                    onClick={() => this.handleInvertFieldSelected(field)}>
                    <ListItemText primary={field.LabelOrName} />
                  </ListItem>  
                )
              })
            }
          </List>
          // <AsyncSelectControl
          //   autoFocus
          //   menuIsOpen
          //   label="Fields"
          //   isMulti
          //   // floatingOptions
          //   onGetOptionsFilterPromise={handleGetFieldOptions}
          //   listValues={SelectedFieldIDs}
          //   onValueChange={this.handleSelectItems("SelectedFieldIDs")}
          // />
        );
        fieldsSelectButton = (
          <Button onClick={() => this.handleSetShowFieldsSelectDialog(true)}>
            FIELDS
          </Button>
        );

        fieldSelectDialog = (
          <MultiUseDialog Details={{
            DialogWidth:"xs",
            FullWidth: true,
            Open: ShowFieldsSelectDialog,
            Title: "Search fields",
            // IsConfirmation: true,
            BodyContent: (
              <div>
                {fieldSelectControl}
              </div>
            ),
            CancelCallback: () => this.handleSetShowFieldsSelectDialog(false),
            CloseCallback: () => this.handleSetShowFieldsSelectDialog(false),
          }} />
        );

        fieldGridItems = GetFilterGridItemsFromFields(
          organizationId,
          SelectedProject.ID,
          true,
          true,
          false,
          Fields.filter(f => this.isFieldSelected(f)),
          SecondaryFields.filter(f => this.isFieldSelected(f)),
          true,
          theme,
          classes,
          this.handleUpdateFieldsState,
          this.handleApiError,
          true,
          this.handleFieldValueKeyDown,
          true,
          true,
        );
      }
      
      optionalCriteriaContent = (
        <Grid container direction="column" spacing={3}>
          <Grid item style={{marginTop:theme.spacing(2)}}>
            <div style={{display:"flex",alignItems:"center"}}>
              <div style={{flexGrow:1}}>
                <TextField
                  autoFocus
                  label="Content & metadata"
                  fullWidth
                  variant="outlined"
                  onChange={e => this.setState({FullText: e.target.value})}
                  value={FullText}
                  onKeyDown={e => this.handleFieldValueKeyDown(null, e)}
                  InputProps={{
                    endAdornment: (FullText) ? (
                      <InputAdornment position="end" className={classes.subTaskClear}>
                        <IconButton
                          tabIndex={-1}
                          edge="end"
                          aria-label="remove"
                          onClick={() => { this.setState({ FullText: "" }); }}
                        >
                          <ClearIcon style={{fontSize:18}} />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                  }}
                />
              </div>
              <div style={{marginLeft:theme.spacing(2)}}>
                {fieldsSelectButton}
              </div>
            </div>
          </Grid>
          {fieldGridItems}
        </Grid>
      );
    }

    let stepContent;
    switch (ActiveStepIndex) {
      case Step_ProjectSelect:
        stepContent = projectContent;
        break;
      case Step_ContentTypeSelect:
        stepContent = contentTypeContent;
        break;
      case Step_OptionalCriteria:
        stepContent = optionalCriteriaContent;
        break;
      default:
        break;
    }

    let nextButtonIsDisabled = true;
    switch (ActiveStepIndex) {
      case Step_ProjectSelect:
        nextButtonIsDisabled = !SelectedProject;
        break;
      case Step_ContentTypeSelect:
        nextButtonIsDisabled = !SelectedContentTypeListValue;
        break;
      case Step_OptionalCriteria:
        nextButtonIsDisabled = false;
        break;
      default:
        break;
    }

    let additionalButtons = [];
    if (ActiveStepIndex === Step_OptionalCriteria && this.userIsOrgAdmin()) {
      if (SelectedTemplateListValue) {
        additionalButtons.push(
          <Button key="b_update" onClick={() => this.handleSetShowUpdateTemplateDialog(true)}
            style={{ fontSize:11 }}
          >
            UPDATE TEMPLATE
          </Button>
        );
      }
      additionalButtons.push(
        <Button key="b_add" onClick={() => this.handleSetShowSaveTemplateDialog(true)}
          style={{ fontSize:11 }}
        >
          {(SelectedTemplateListValue) ? "SAVE AS NEW" : "SAVE AS TEMPLATE"}
        </Button>
      );
      // if (SelectedTemplateListValue) {
      //   additionalButtons.push(
      //     <Button key="b_delete" onClick={() => this.handleSetShowDeleteTemplateDialog(true, 
      //       SelectedTemplateListValue.value)}
      //       style={{ fontSize:11 }}
      //     >
      //       DELETE
      //     </Button>
      //   );
      // }
    }

    const stepper = GetStepper(() => this.state, state => this.setState(state),
      this.handleSkip, this.handleMove, this.handleFinish, classes, stepContent, nextButtonIsDisabled, "SEARCH",
      theme.palette.background.pane, additionalButtons);

    const saveTemplateDialog = (ShowSaveTemplateDialog || ShowUpdateTemplateDialog) ? (
      <MultiUseDialog Details={{
        Open: true,
        Title:(ShowUpdateTemplateDialog) ? "Update template" : "Add template",
        IsConfirmation: true,
        RequireTextInput1:true,
        TextInput1Label:"Name",
        TextInput1DefaultValue:(ShowUpdateTemplateDialog) ? SelectedTemplateListValue.name : undefined,
        // TextInput1PlaceHolder:"",
        CancelCallback:(ShowUpdateTemplateDialog)
          ? () => this.handleSetShowUpdateTemplateDialog(false)
          : () => this.handleSetShowSaveTemplateDialog(false),
        CloseCallback:(ShowUpdateTemplateDialog)
          ? () => this.handleSetShowUpdateTemplateDialog(false)
          : () => this.handleSetShowSaveTemplateDialog(false),
        ConfirmLabel:(ShowUpdateTemplateDialog) ? "UPDATE" : "ADD",
        ConfirmCallback:name => this.handleSaveAsTemplate(name, ShowUpdateTemplateDialog),
      }} />
    ) : null;

    const deleteTemplateDialog = (ShowDeleteTemplateDialog) ? (
      <MultiUseDialog Details={{
        Open: true,
        Title:"Delete template?",
        IsConfirmation: true,
        BodyText: `Are you sure you want to delete '${SearchTemplateToDelete.Name}'?`,
        CancelCallback:() => this.handleSetShowDeleteTemplateDialog(false),
        CloseCallback:() => this.handleSetShowDeleteTemplateDialog(false),
        ConfirmLabel: "DELETE",
        ConfirmCallback:this.handleDeleteTemplate,
      }} />
    ) : null;

    const progressIndicator = (ShowProgressIndicatorImmediately) ? (
      <ProgressIndicator
        constrained
        showImmediately />
    ) : null;

    const popover = (
      <Popover
        open={Boolean(PopoverText)}
        anchorEl={(this.LeftPaneContentRef && !this.LeftPaneContentRef.hasOwnProperty('current'))
          ? this.LeftPaneContentRef
          : undefined
        }
        onClose={() => this.handleShowPopover(null)}
        anchorOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
        transformOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        {PopoverText}
      </Popover>
    );

    const leftPaneContent = (
      <div ref={instance => this.LeftPaneContentRef = instance}>
        {progressIndicator}
        {popover}
        {templateListDiv}
        {stepper}
        {fieldSelectDialog}
        {saveTemplateDialog}
        {deleteTemplateDialog}
      </div>
    );

    return (
      <ItemCollectionBase
        {...restProps}

        organizationId={organizationId}
        pageTitle="Search"
        showOrgAsTitleOnDesktop
        contentUri={this.Collection.ContentUri}
        contentUriParams={{
          contentType: SelectedContentTypeListValue
            && SelectedContentTypeListValue.value
            && SelectedContentTypeListValue.value.ContentType,
          parentId: SelectedContentTypeListValue
            && SelectedContentTypeListValue.value
            && SelectedContentTypeListValue.value.AssetID,
        }}
        fullTextFilter={FullText}
        contentUriMetaFieldFilters={GetFilterFieldsAsMetaFieldFilters(
          Fields.filter(f => this.isFieldSelected(f)),
          SecondaryFields.filter(f => this.isFieldSelected(f)),
        )}

        leftPaneContent={leftPaneContent}
        leftPaneInnerStyle={{
          width:400,
        }}

        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}

        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        onConnectRefreshItemsFunction={this.connectRefreshItemsFunction}

        hideFilters
        preventReloadOnUpdate
        startupMessage={StartupMessage}
        hideSensitiveFields={this.Collection.HideSensitiveFields}
        apiError={ApiError}
        alert={Alert}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(AdvancedSearch);
