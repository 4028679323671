import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ClearIcon from '@material-ui/icons/Clear';

import RichTextEditor from './RichTextEditor';
import AsyncSelectControl from './AsyncSelectControl';
import SelectControl from './SelectControl';
import TaskChecklist from './TaskChecklist';
// import NumericTextField from './NumericTextField';

import NumericTextField from '../Components/NumericTextField';
import {
  GetFormattedDescription,
  GetDueOnStyle,
} from '../Util/Tasks';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
} from '../Util/Tags';
import {
  GetAddressBookItemsPromise,
} from '../Util/AddressBookItems';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';

import {
  GetUserValue,
} from '../Util/Properties';
import TaskPriorityType from '../Model/TaskPriorityType';

import { IsMobile } from '../Util/MobileDetector';
import dateformat from 'dateformat';
import debounce from 'es6-promise-debounce';

import API, {
  GetTaskMilestonesPathForApi,
  GetTaskStatesPathForApi,
} from '../Util/api';
import {
  GetTaskMilestonesPromise,
} from '../Util/TaskMilestones';
import {
  GetNewTaskMilestone,
} from '../Model/TaskMilestone';
import {
  GetTaskStatesPromise,
} from '../Util/TaskStates';
import {
  GetNewTaskState,
} from '../Model/TaskState';

const getDueInMultiplierOptions = () => {
  let options = [];
  for (let i = 1; i <= 100; i++) {
    options.push({ value: i, label: i.toString() });
  }
  return options;
}
const hourMultiplierOption = multiplier => { return { value: "hour", label: `Hour${multiplier > 1 ? "s" : ""}` }};
const dayMultiplierOption = multiplier => { return { value: "day", label: `Day${multiplier > 1 ? "s" : ""}` }};
const weekMultiplierOption = multiplier => { return { value: "week", label: `Week${multiplier > 1 ? "s" : ""}` }};
const getDueInIntervalOptions = multiplier => [
  dayMultiplierOption(multiplier),
  weekMultiplierOption(multiplier),
];
const getDurationIntervalOptions = multiplier => multiplier ? [
  hourMultiplierOption(multiplier),
  dayMultiplierOption(multiplier),
  weekMultiplierOption(multiplier),
] : [];
const getPriorityOptions = () => {
  return TaskPriorityType.map(p => {
    return {
      label: p.Label,
      value: p.Type,
    };
  });
}

const styles = theme => ({
  TaskDescription: {
    ...GetRichTextEditorStyles(theme),
  },
  descriptionAndChecklist: {
    flexGrow:1,
    overflow:(IsMobile()) ? undefined : "auto",
    marginTop:theme.spacing(1),
  },
  descriptionAndChecklistContainer: {
    display:"flex",
    height:"100%",
    overflow:"hidden",
  },
  descriptionContainer: {
    flexGrow:1,
    minWidth:"60%",
    maxWidth:"60%",
  },
  description: {
    fontSize:16,
    marginTop:theme.spacing(2),
  },
  tagsAndChecklistContainer: {
    marginLeft:4,
    marginTop: theme.spacing(6),
    width:"100%",
    display:"flex",
    flexDirection:"column",
    overflowY:"auto",
  },
  tagsContainer: {
    marginLeft:theme.spacing(2),
    marginBottom:theme.spacing(3),
    paddingTop:theme.spacing(1),
  },
});

class TaskDialogGrid extends Component {
  constructor(props) {
    super(props);

    let DueOnDateString = "";
    let DueOnTimeString = "";
    if (this.props.Task && this.props.Task.DueOn) {
      let dueOnDate = new Date(this.props.Task.DueOn);
      if (dueOnDate > new Date("2000-01-01")){
        DueOnDateString = dateformat(dueOnDate, "yyyy-mm-dd");
        DueOnTimeString = dateformat(dueOnDate, "HH:MM");
      }
    }

    this.state = {
      DueOnDateString,
      DueOnTimeString,
      ScrollTop: 0,
      TaskForAssignmentLabel: this.props.Task,
      TaskMilestones: [],
      TaskStates: [],
      TagListValues: GetTagListValuesFromTagsObject(this.props.Task),
    }

    this.LastAddressBookItems = [];
    this.FinalChecklistItemRef = React.createRef();
  }

  updateTaskDueOn = (dueOnDateString, dueOnTimeString) => {
    let dueOn = null;
    if (dueOnDateString) {
      dueOn = new Date(`${dueOnDateString}T${(dueOnTimeString) ? dueOnTimeString : "12:00"}:00`);  
    }
    this.props.onDateTimeChange("DueOn", dueOn);
  }

  clearDueOn = () => {
    this.setState({DueOnDateString:"",DueOnTimeString:""});
    this.updateTaskDueOn();
  }

  handleDueOnDateChange = e => {
    if (!e || !e.target || !e.target.value) {
      return this.clearDueOn();
    }
    let DueOnDateString = e.target.value;
    let stateToUpdate = {DueOnDateString};
    let DueOnTimeString = this.state.DueOnTimeString;
    if (!DueOnTimeString) {
      DueOnTimeString = "12:00";
      stateToUpdate.DueOnTimeString = DueOnTimeString;
    }
    this.setState(stateToUpdate);
    this.updateTaskDueOn(DueOnDateString, DueOnTimeString);
  }

  handleDueOnTimeChange = e => {
    let DueOnTimeString = e.target.value;
    this.setState({DueOnTimeString});
    this.updateTaskDueOn(this.state.DueOnDateString, DueOnTimeString);
  }

  handleDueInMultiplierChange = value => {
    if (!value) {
      value = 0;
    }
    this.props.onNumberChange("DueInMultiplier")(value);
    if (!value) {
      this.handleDueInIntervalChange("");
    } else if (!this.state.DueInInterval) {
      this.handleDueInIntervalChange("week");
    }
  }

  handleDueInIntervalChange = value => {
    this.props.onStringChange("DueInInterval")(value);
  }

  handleDurationIntervalChange = value => {
    this.props.onDurationChange(this.props.Task.DurationMultiplier, value);
  }

  handleDurationMultiplierChange = e => {
    const multiplier = (e && e.target && e.target.value) || "0";
    const interval = (!multiplier)
      ? ""
      : this.props.Task.DurationInterval || "day";
    this.props.onDurationChange(multiplier, interval);
  }

  isDurationMultiplierAllowed = values => {
    const { floatValue } = values;
    return !floatValue || floatValue <= 99;
  }

  handlePriorityChange = value => {
    if (value === undefined || value === null) {
      value = 0;
    }
    this.props.onNumberChange("Priority")(value);
  }

  handleMilestoneChange = selectedOption => {
    let secondaryName, secondaryValue;
    if (selectedOption === undefined || selectedOption === null) {
      selectedOption = { label: "", value: "" };
    } else {
      const taskMilestoneFinder = this.state.TaskMilestones.filter(tm => tm.ID === selectedOption.value);
      if (taskMilestoneFinder.length) {
        secondaryName = "TaskMilestoneName";
        secondaryValue = taskMilestoneFinder[0].Name;
      }
    }
    this.props.onStringChange("TaskMilestoneID", false, secondaryName, secondaryValue)(selectedOption.value);
    if (this.props.onMilestoneValueChange) {
      this.props.onMilestoneValueChange(selectedOption);
    }
  }

  handleMilestoneCreateOption = Name => {
    if (!Name) {
      return;
    }
    Name = Name.trim();

    let TaskMilestones = [...this.state.TaskMilestones];
    const newMilestone = GetNewTaskMilestone(Name);
    API.post(GetTaskMilestonesPathForApi(this.props.organizationId, this.props.projectId), [newMilestone])
      .then(resp => {
        if (resp.data && resp.data.length) {
          const serverMilestone = resp.data[0];
          TaskMilestones.push(serverMilestone);
          this.setState({TaskMilestones});
          this.handleMilestoneChange({value:serverMilestone.ID,label:serverMilestone.Name});
          if (this.props.onTaskMilestoneCreated) {
            this.props.onTaskMilestoneCreated(serverMilestone);
          }
        }
      })
      .catch(this.handleApiError);
  }

  handleStateChange = selectedOption => {
    let secondaryName, secondaryValue;
    if (selectedOption === undefined || selectedOption === null) {
      selectedOption = { label: "", value: "" };
    } else {
      const taskStateFinder = this.state.TaskStates.filter(tm => tm.ID === selectedOption.value);
      if (taskStateFinder.length) {
        secondaryName = "TaskStateName";
        secondaryValue = taskStateFinder[0].Name;
      }
    }
    this.props.onStringChange("TaskStateID", false, secondaryName, secondaryValue)(selectedOption.value);
    if (this.props.onStateValueChange) {
      this.props.onStateValueChange(selectedOption);
    }
  }

  handleStateCreateOption = Name => {
    if (!Name) {
      return;
    }
    Name = Name.trim();

    let TaskStates = [...this.state.TaskStates];
    const newState = GetNewTaskState(Name);
    API.post(GetTaskStatesPathForApi(this.props.organizationId, this.props.projectId), [newState])
      .then(resp => {
        if (resp.data && resp.data.length) {
          const serverState = resp.data[0];
          TaskStates.push(serverState);
          this.setState({TaskStates});
          this.handleStateChange({value:serverState.ID,label:serverState.Name});
          if (this.props.onTaskStateCreated) {
            this.props.onTaskStateCreated(serverState);
          }
        }
      })
      .catch(this.handleApiError);
  }

  handleRichTextEditorChange = (stateAsJsonString, stateAsHtml, stateAsPlainText) => {
    this.props.onRichTextChange(
      "DescriptionRichTextJson", stateAsJsonString, 
      "DescriptionHtml", stateAsHtml,
      "Description", stateAsPlainText,
    );
  }

  handleGetAddressBookItemsPromise = debounce(filter => {
    return GetAddressBookItemsPromise(this.props.organizationId, this.props.projectId, 
      true, !this.props.isWorkspace, this.props.isWorkflow, this.props.isWorkflow, 
      filter)
      .then(items => {
        const addressBookItems = items.map(abi => { 
          if (abi.ProjectMemberID) {
            if (this.props.isWorkflow) {
              return ({
                value: `ProjectMemberID:${abi.ProjectMemberID}`,
                plainLabel: `${abi.EmailLower} (member${(abi.Name) ? " " + abi.Name : ""})`,
                label: GetUserValue(abi.EmailLower,
                  (abi.Name)
                    ? `${abi.Name} (member ${abi.EmailLower})` 
                    : `${abi.EmailLower} (member)`,
                  "", false, undefined, {}, {}, true,
                  ),
              });
            } else {
              return ({
                value: abi.EmailLower,
                plainLabel: `${abi.EmailLower} (member)`,
                label: GetUserValue(abi.EmailLower,
                  (abi.Name) 
                    ? `${abi.Name} (member)` 
                    : `${abi.EmailLower} (member)`,
                  "", false, undefined, {}, {}, true,
                  ),
              });
            }
          } else if (abi.FieldID) {
            return ({
              value: `FieldID:${abi.FieldID}`,
              label: `${abi.Name} (field)`,
            }); 
          } else if (abi.FormTemplateID && abi.FormTemplateFieldID) {
            return ({
              value: `FormTemplateID:${abi.FormTemplateID} FormTemplateFieldID:${abi.FormTemplateFieldID}`,
              label: `${abi.Name} (form field, ${abi.FormTemplateName})`,
            }); 
          } else {
            return ({
              value: abi.EmailLower,
              plainLabel: abi.EmailLower + ((abi.Name) ? " (" + abi.Name +")" : ""),
              label: GetUserValue(abi.EmailLower,
                (abi.Name)
                  ? abi.Name
                  : abi.EmailLower,
                "", false, undefined, {}, {}, true,
                ),
            });
          }
        });
        // Update labels with current values (if different)
        this.setAddressListValues(addressBookItems);
        this.LastAddressBookItems = addressBookItems;
        return addressBookItems;
      })
      .catch(this.props.onApiError);
  }, 250);

  setAddressListValues = (addressBookItems, valueToMatch) => {
    let TaskForAssignmentLabel = {...this.state.TaskForAssignmentLabel};
    if (!TaskForAssignmentLabel) {
      return;
    }
    let matching = addressBookItems.find(abi => abi.value === valueToMatch);
    if (matching) {
      TaskForAssignmentLabel.AssignmentUserEmail = matching.value;
      TaskForAssignmentLabel.AssignmentUserName = matching.label;
    } else {
      TaskForAssignmentLabel.AssignmentUserEmail = valueToMatch;
      TaskForAssignmentLabel.AssignmentUserName = "";
    }
    this.setState({TaskForAssignmentLabel});
  }

  handleAssignmentCreateOption = value => {
    this.props.onAssignmentCreateOption(value, this.handleAssignmentValueChange);
  }

  handleAssignmentValueChange = selectedOption => {
    const TaskForAssignmentLabel = {...this.state.TaskForAssignmentLabel};
    const originalAssignmentUserEmail = TaskForAssignmentLabel.AssignmentUserEmail;
    this.setAddressListValues(this.LastAddressBookItems, selectedOption.value);
    this.props.onAssignmentValueChange(selectedOption)
      .catch(err => {
        this.setAddressListValues(this.LastAddressBookItems, originalAssignmentUserEmail);
      });
  }

  handleLoadMilestonesAndReturnOptionsPromise = debounce(nameFilter => {
    if (!this.props.Task) {
      return null;
    }
    return GetTaskMilestonesPromise(this.props.organizationId, this.props.projectId,
      this.props.Task.ID, true, 
      (this.props.isWorkspace && !this.props.workspaceApprovalId),
      (this.props.isWorkspace && Boolean(this.props.workspaceApprovalId)),
      this.props.workspaceApprovalId,
    )
      .then(taskMilestoneList => {
        const TaskMilestones = taskMilestoneList.TaskMilestones;
        this.setState({TaskMilestones});
        if (TaskMilestones && TaskMilestones.length) {
          return TaskMilestones.map(tm => {
            return { label: tm.Name, value: tm.ID };
          });
        } else {
          return null;
        }
      })
      .catch(this.props.onApiError);
  }, 250)

  handleLoadStatesAndReturnOptionsPromise = debounce(nameFilter => {
    if (!this.props.Task) {
      return null;
    }
    return GetTaskStatesPromise(this.props.organizationId, this.props.projectId,
      this.props.Task.ID, true, 
      (this.props.isWorkspace && !this.props.workspaceApprovalId),
      (this.props.isWorkspace && Boolean(this.props.workspaceApprovalId)),
      this.props.workspaceApprovalId,
    )
      .then(taskStateList => {
        const TaskStates = taskStateList.TaskStates;
        this.setState({TaskStates});
        if (TaskStates && TaskStates.length) {
          return TaskStates.map(ts => {
            return { label: ts.Name, value: ts.ID };
          });
        } else {
          return null;
        }
      })
      .catch(this.props.onApiError);
  }, 250)

  render() {
    const {
      DueOnDateString,
      DueOnTimeString,
      ScrollTop,
      TaskForAssignmentLabel,
      TagListValues,
    } = this.state;
    const {
      theme,
      classes,
      organizationId,
      projectId,
      Task,
      workspaceApprovalId,
      additionalTaskPropertiesForCreation,
      onStringChange,
      onSubTaskAdded,
      onSubTaskChanged,
      onSubTaskAddedAfter,
      onStartMoveSubTask,
      onMoveSubTask,
      onAbortMoveSubTask,
      onEndMoveSubTask,
      hideName,
      showDueOn,
      showDueIn,
      isWorkflow,
      isCreateNew,
      isWorkspace,
      isProjectAdmin,
      isUserRestrictedToAssignedTasks,
      onTagListValuesChanged,
      onApiError,
    } = this.props;

    const taskIsInactive = (!isCreateNew && !isWorkflow && Task && Task.Result !== "");

    const selectedAssignmentOption = 
      (TaskForAssignmentLabel && TaskForAssignmentLabel.AssignmentUserEmail)
        ? { 
          label: TaskForAssignmentLabel.AssignmentUserName || TaskForAssignmentLabel.AssignmentUserEmail,
          value: TaskForAssignmentLabel.AssignmentUserEmail,
        }
        : (Task && Task.AssignmentUserEmail)
          ? { 
            label: GetUserValue(Task.AssignmentUserEmail, Task.AssignmentUserName),
            value: Task.AssignmentUserEmail,
          }
          : null;

    const nameGridItem = (!hideName)
      ? (
        <Grid item key="name" xs={12} lg={6} xl={4}>
          <TextField
            variant="outlined"
            label="Name"
            value={(Task && Task.Name) || ""}
            autoFocus={isCreateNew}
            onChange={onStringChange("Name")}
            fullWidth
            disabled={taskIsInactive || isWorkspace}
          />
        </Grid>
      ) : null;

    const getClearDueOnDateInputAdornment = () => (
      <InputAdornment position="end">
        <IconButton
          tabIndex={-1}
          edge="end"
          aria-label="clear"
          onClick={() => this.handleDueOnDateChange("")}
        >
          <ClearIcon style={{fontSize:18}} />
        </IconButton>
      </InputAdornment>
    );

    const dueOnGridItems = (showDueOn && (!isWorkspace || DueOnDateString !== ""))
      ? [
        <Grid item key="dueOnDate" xs={7} lg={3} xl={2} style={{paddingRight:0}}>
          <TextField
            variant="outlined"
            label="Due On"
            value={DueOnDateString}
            onChange={this.handleDueOnDateChange}
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true, }}
            inputProps={{
              max:"9999-12-31",
              style:(Task && !Task.Result) ? GetDueOnStyle(Task.DueOn) : undefined,
            }}
            InputProps={{
              endAdornment: (!taskIsInactive && !isWorkspace && DueOnDateString) ? getClearDueOnDateInputAdornment() : undefined,
            }}
            disabled={taskIsInactive || isWorkspace}
          />
        </Grid>,
        <Grid item key="dueOnTime" xs={5} lg={3} xl={2}>
          <TextField
            variant="outlined"
            value={DueOnTimeString}
            onChange={this.handleDueOnTimeChange}
            fullWidth
            disabled={!DueOnDateString || taskIsInactive || isWorkspace}
            type="time"
            InputLabelProps={{ shrink: true, }}
            inputProps={{
              style:(Task && !Task.Result) ? GetDueOnStyle(Task.DueOn) : undefined,
            }}
          />
        </Grid>
      ]
      : null;

    const dueInGridItems = (showDueIn)
      ? [
        <Grid item key="dueInMultiplier" xs={7} md={(isWorkflow) ? 2 : 6} lg={(isWorkflow) ? 1 : 3} style={{paddingRight:0}}>
          <SelectControl
            id="select_dueInMultiplier"
            label="Due In"
            forceShrinkLabel
            options={getDueInMultiplierOptions()}
            value={(Task.DueInMultiplier) ? Task.DueInMultiplier : null}
            onValueChange={this.handleDueInMultiplierChange}
          />
        </Grid>,
        <Grid item key="dueInInterval" xs={5} md={(isWorkflow) ? 2 : 6} lg={(isWorkflow) ? 3 : 3}>
          <SelectControl
            id="select_dueInInterval"
            forceShrinkLabel
            hideEmpty
            options={getDueInIntervalOptions(Task.DueInMultiplier)}
            disabled={!Task.DueInMultiplier} 
            value={Task.DueInInterval}
            onValueChange={this.handleDueInIntervalChange}
          />
        </Grid>
      ]
      : null;

    const durationGridItems = [
      <Grid item key="durationMultiplier" xs={4} md={(isWorkflow) ? 2 : 4} lg={2} xl={1} style={{paddingRight:0}}>
        <NumericTextField
          id="durationMultiplier"
          label="Duration"
          value={(Task && Task.DurationMultiplier && Task.DurationMultiplier.toString()) || ""}
          onValueChange={this.handleDurationMultiplierChange}
          isAllowedFunc={this.isDurationMultiplierAllowed}
          decimalPlaces={2}
          forceShrinkLabel
          disabled={taskIsInactive || isWorkspace}
        />
      </Grid>,
      <Grid item key="durationInterval" xs={8} md={(isWorkflow) ? 2 : 8} lg={2} xl={3}>
        <SelectControl
          id="select_durationInterval"
          forceShrinkLabel
          hideNone
          options={getDurationIntervalOptions(Task && Task.DurationMultiplier)}
          disabled={(Task && !Task.DurationMultiplier) || taskIsInactive || isWorkspace} 
          value={(Task && Task.DurationMultiplier) ? Task.DurationInterval : null}
          onValueChange={this.handleDurationIntervalChange}
        />
      </Grid>
    ];

    const priorityGridItem = (Task && (!isWorkspace || Task.Priority > 0)) ? (
      <Grid item key="priority" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 6} xl={(isWorkflow) ? 4 : 2}>
        <SelectControl
          id="select_priority"
          label="Priority"
          forceShrinkLabel
          hideEmpty
          options={getPriorityOptions()}
          value={Task && Task.Priority}
          onValueChange={this.handlePriorityChange}
          disabled={taskIsInactive || isWorkspace
            || (
              (additionalTaskPropertiesForCreation
                && additionalTaskPropertiesForCreation.Priority)
                ? additionalTaskPropertiesForCreation.Priority > -1
                : false
            )
          }
        />
      </Grid>
    ) : null;

    const milestoneGridItem = (Task && (!isWorkspace || Task.TaskMilestoneID !== "")) ? (
      <Grid item key="milestone" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 4} xl={(isWorkflow) ? 4 : 3}>
        <AsyncSelectControl label="Milestone" 
          // forceShrinkLabel
          noFlexWrap // This prevents a gap/blank link at the bottom of the value container
          floatingOptions
          onGetOptionsFilterPromise={this.handleLoadMilestonesAndReturnOptionsPromise}
          listValues={
            (Task && Task.TaskMilestoneID)
              ? {
                label: Task.TaskMilestoneName,
                value: Task.TaskMilestoneID,
              }
              : null
          }
          onValueChange={this.handleMilestoneChange}
          onCreateOption={(isProjectAdmin || isWorkflow) ? this.handleMilestoneCreateOption : undefined}
          autoReloadOnValueChange
          disabled={taskIsInactive || isWorkspace
            || (
              (additionalTaskPropertiesForCreation)
                ? additionalTaskPropertiesForCreation.TaskMilestoneID !== ""
                : false
            )
          }
        />
      </Grid>
    ) : null;

    const stateGridItem = (Task && (!isWorkspace || Task.TaskStateID !== "")) ? (
      <Grid item key="state" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 4} xl={(isWorkflow) ? 4 : 3}>
        <AsyncSelectControl label="Task State" 
          // forceShrinkLabel
          noFlexWrap // This prevents a gap/blank link at the bottom of the value container
          floatingOptions
          onGetOptionsFilterPromise={this.handleLoadStatesAndReturnOptionsPromise}
          listValues={
            (Task && Task.TaskStateID)
              ? {
                label: Task.TaskStateName,
                value: Task.TaskStateID,
              }
              : null
          }
          onValueChange={this.handleStateChange}
          onCreateOption={(isProjectAdmin || isWorkflow) ? this.handleStateCreateOption : undefined}
          autoReloadOnValueChange
          disabled={taskIsInactive || isWorkspace
            || (
              (additionalTaskPropertiesForCreation)
                ? additionalTaskPropertiesForCreation.TaskStateID !== ""
                : false
            )
          }
        />
      </Grid>
    ) : null;

    const description = (!taskIsInactive)
      ? (
        <RichTextEditor
          initialStateJson={Task && Task.DescriptionRichTextJson}
          initialStatePlainText={Task && Task.Description}
          parentScrollTop={ScrollTop}
          onChange={this.handleRichTextEditorChange}
          onApiError={onApiError}
          label="Description"
        />
      )
      : (
        <div className={classes.description}>
          {GetFormattedDescription(Task, theme, classes)}
        </div>
      );

    const assignmentDisabled = taskIsInactive
      || isWorkspace
      || (isUserRestrictedToAssignedTasks && !isProjectAdmin)
      || (additionalTaskPropertiesForCreation
        && additionalTaskPropertiesForCreation.AssignmentUserEmail
        && additionalTaskPropertiesForCreation.AssignmentUserEmail !== "");
    const assignmentGridItemInternal = (
      <Grid item key="assignment" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 6} xl={4}>
          <AsyncSelectControl label="Assigned to" 
            // forceShrinkLabel
            noFlexWrap // This prevents a gap/blank link at the bottom of the value container
            floatingOptions
            onGetOptionsFilterPromise={this.handleGetAddressBookItemsPromise}
            listValues={selectedAssignmentOption}
            onValueChange={this.handleAssignmentValueChange}
            onCreateOption={this.handleAssignmentCreateOption}
            autoReloadOnValueChange
            notClearable
            disabled={assignmentDisabled}
          />
      </Grid>
    );
    const assignmentGridItem = (isWorkspace && assignmentDisabled)
      ? (
        <Tooltip title="Assignments cannot be changed in My Work.">
          {assignmentGridItemInternal}
        </Tooltip>
      )
      : assignmentGridItemInternal;

    const tagsControl = GetTagsControl(organizationId, projectId, (Task) ? Task.ID : null, 
      (isWorkspace && !workspaceApprovalId),
      (isWorkspace && Boolean(workspaceApprovalId)), workspaceApprovalId, true,
      TagListValues, TagListValues => this.setState({TagListValues}),
      onTagListValuesChanged, (Task && Boolean(Task.Result)), -1, null, !isWorkflow);

    const taskChecklist = (Task)
      ? (
        <TaskChecklist
          Task={Task}
          onSubTaskAdded={onSubTaskAdded}
          onSubTaskChanged={onSubTaskChanged}
          onSubTaskAddedAfter={onSubTaskAddedAfter}
          onStartMoveSubTask={onStartMoveSubTask}
          onMoveSubTask={onMoveSubTask}
          onAbortMoveSubTask={onAbortMoveSubTask}
          onEndMoveSubTask={onEndMoveSubTask}
          disabled={taskIsInactive}
        />
      ) : null;

    const desktopDescriptionAndTagsAndChecklist = (!IsMobile())
      ? (
        <div className={classes.descriptionAndChecklist}>
          <div className={classes.descriptionAndChecklistContainer}>
            <div className={classes.descriptionContainer}>
              {description}
            </div>
            <div className={classes.tagsAndChecklistContainer}>
              <div className={classes.tagsContainer}>
                {tagsControl}
              </div>
              {taskChecklist}
            </div>
          </div>
        </div>
      ) : null;

    const mobileDescription = (IsMobile())
      ? ( 
        <div style={{marginTop:theme.spacing(1)}}>
          {description}
        </div>
      ) : null;

    return (
      <div
        style={{
          display:"flex",
          flexDirection:"column",
          height:"100%",
          overflowY: (IsMobile()) ? "auto" : undefined,
          overflowX: "hidden",
          // This prevents the horizontal scrollbar from showing due to material ui negative margin
          // overflowX:"hidden",
          paddingTop:theme.spacing(1),
        }}
        onScroll={e => this.setState({ScrollTop: e.target.scrollTop})}
      >
        <div>
          <Grid container spacing={2}>
            {nameGridItem}
            {assignmentGridItem}
            {dueOnGridItems}
            {dueInGridItems}
            {priorityGridItem}
            {milestoneGridItem}
            {stateGridItem}
            {durationGridItems}
          </Grid>
        </div>

        {desktopDescriptionAndTagsAndChecklist}
        {mobileDescription}
      </div>
    );
  }
}

TaskDialogGrid.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  Task: PropTypes.object,
  additionalTaskPropertiesForCreation: PropTypes.object,
  onStringChange: PropTypes.func.isRequired,
  onRichTextChange: PropTypes.func.isRequired,
  onNumberChange: PropTypes.func.isRequired,
  onDateTimeChange: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onAssignmentValueChange: PropTypes.func.isRequired,
  onAssignmentCreateOption: PropTypes.func,
  onMilestoneValueChange: PropTypes.func,
  onStateValueChange: PropTypes.func,
  onTagListValuesChanged: PropTypes.func,
  onSubTaskAdded: PropTypes.func.isRequired,
  onSubTaskChanged: PropTypes.func.isRequired,
  onSubTaskAddedAfter: PropTypes.func.isRequired,
  onStartMoveSubTask: PropTypes.func.isRequired,
  onMoveSubTask: PropTypes.func.isRequired,
  onAbortMoveSubTask: PropTypes.func.isRequired,
  onEndMoveSubTask: PropTypes.func.isRequired,
  onTaskMilestoneCreated: PropTypes.func,
  onTaskStateCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  showDueOn: PropTypes.bool,
  showDueIn: PropTypes.bool,
  isWorkspace: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  isUserRestrictedToAssignedTasks: PropTypes.bool,
  workspaceApprovalId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(TaskDialogGrid);