import { GetFieldForFilterAndSort } from '../Util/Field';
import {
  TextFieldOperators,
} from '../Util/Search';

export const GetAdvancedSearchResultsCollectionFieldsByContentType = contentType => {
	let filterSortFields = [];
	if (contentType !== "AssetItem") {
		let label = "Name";
		switch (contentType) {
		case "Document":
			label = "Document name";
			break;
		case "Task":
			label = "Task name";
			break;
		case "FormTemplate":
			label = "Form name";
			break;
		default:
			label = "Name";
			break;
		}
		filterSortFields.push(
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[Name].keyword",
				label,
				"FieldType_Text",
				"Meta_text_kw256lc[Name]",
			),
		);
	}
	if (contentType === "Task") {
		filterSortFields.push(
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[AssignmentUserEmail].keyword",
				"Assigned to",
				"FieldType_Text",
				null,
			    [
			      {
			          value: "equals",
			          label: "equals",
			      },
			      {
			        value: "notEquals",
			        label: "does not equal",
			      },
			    ],
			    "addressBookItemsList",
			    true,
			),
			GetFieldForFilterAndSort(
				"Meta_date_str256[DueOn].string",
				"Due On",
				"FieldType_Date",
			),
			// GetFieldForFilterAndSort(
			// 	"Meta_long[Duration]",
			// 	"Duration",
			// 	"FieldType_Number",
			// 	null,
			// 	null,
			// 	null,
			// 	false,
			// 	true,
			// ),
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[MilestoneName].keyword",
				"Milestone",
				"FieldType_Text",
				null,
				[
			      {
			          value: "equals",
			          label: "equals",
			      },
			      {
			      	value: "notEquals",
			      	label: "does not equal",
			      },
			    ],
			  	"taskMilestones",
			  	false,
			  	false,
			  	null,
			  	"Meta_text_kw256lc[MilestoneName]",
			  	true,
			  	TextFieldOperators,
			),
			GetFieldForFilterAndSort(
				"Meta_int[Priority]",
				"Priority",
				"FieldType_Text", // We use a text field internally, but use FieldType_Number for API queries
				null,
				[
					{
					    value: "equals",
					    label: "equals",
					},
				],
		    	"taskPriorityTypes",
		    	false,
		    	false,
		    	"FieldType_Number", // We use a text field internally, but use FieldType_Number for API queries
			),
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[StateName].keyword",
				"Task State",
				"FieldType_Text",
				null,
				[
			      {
			          value: "equals",
			          label: "equals",
			      },
			      {
			      	value: "notEquals",
			      	label: "does not equal",
			      },
			    ],
		    	"taskStates",
		    	false,
		    	false,
		    	null,
		    	"Meta_text_kw256lc[StateName]",
		    	true,
		    	TextFieldOperators,
			),
		);
	}
	
	filterSortFields.push(
		GetFieldForFilterAndSort(
			"Meta_text_kw50lc[Tag].keyword",
			"Tags",
			"FieldType_Text",
			"Meta_text_kw50lc[Tag]",
			null,
	  	"tags",
	  	true,
	  	false,
	  	null,
	  	null,
	  	false,
	  	null,
	  	true,
		),
	);

	return filterSortFields;
}