import React from 'react';

import Grid from '@material-ui/core/Grid';

import SelectControl from '../Components/SelectControl';
import FieldInput from '../Components/FieldInput';
import GridWithConditionalRendering from '../Components/GridWithConditionalRendering';
import API from './api';
import { CustomMaskValue } from '../Model/Field';
import debounce from 'es6-promise-debounce';
import { ValidateEmail } from '../Util/Regex';
import {
  GetFieldPathForApi,
  GetFieldListItemsPathForApi,
  GetUserOrganizationProjectDocumentFolderFieldListItemsPathForApi,
  GetUserOrganizationProjectTaskFieldListItemsPathForApi,
  GetUserOrganizationProjectApprovalTaskFieldListItemsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskFieldListItemsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemProjectFieldListItemsPathForApi,
} from '../Util/api';
import {
  TextFieldOperators,
  BoolFieldOperators,
  NumericDateFieldOperators,
} from '../Util/Search';
import {
  TryGetFilterFieldPromiseFuncById,
} from '../Util/Filters';
import red from '@material-ui/core/colors/red';

export const RequiredFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:red[500],
  },
  "& .MuiCheckbox-root": {
    "& .MuiSvgIcon-root": {
      fill:red[500],
    },
  },
  "& .MuiRadio-root": {
    "& .MuiSvgIcon-root": {
      fill:red[500],
    },
  },
};

export function GetRegexMaskFromMask(mask) {
  //let sample = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  let rKeyboard = /[a-zA-Z0-9 ,./<>?;:"'`~!@#$%^&*()[\]{}_\-+=|\\]/;
  let rLetter = /[a-zA-Z]/;
  let rLetterOptional = /[a-zA-Z ]/;
  let rDigit = /[0-9]/;
  let rDigitOptional = /[0-9 ]/;
  let rLetterOrDigit = /[a-zA-Z0-9]/;
  let rLetterOrDigitOptional = /[a-zA-Z0-9 ]/;
  let regex = [];
  for (let i = 0; i < mask.length; i++) {
    switch (mask[i]) {
      case "&":
      regex.push(rKeyboard);
      break;
      case "C":
      regex.push(rKeyboard);
      break;
      case "L":
      regex.push(rLetter);
      break;
      case "?":
      regex.push(rLetterOptional);
      break;
      case "0":
      case "#":
      regex.push(rDigit);
      break;
      case "9":
      regex.push(rDigitOptional);
      break;
      case "A":
      regex.push(rLetterOrDigit);
      break;
      case "a":
      regex.push(rLetterOrDigitOptional);
      break;
      case ">":
      break;
      case "<":
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        regex.push(mask[i]);
      }
      break;      
      default:
      regex.push(mask[i]);
      break;
    }
  }
  return regex;
}

export function GetRequiredPositionsFromMask(mask) {
  let required = [];
  for (let i = 0; i < mask.length; i++) {
    switch (mask[i]) {
      case "&":
      required.push(true);
      break;
      case "C":
      required.push(false);
      break;
      case "L":
      required.push(true);
      break;
      case "?":
      required.push(false);
      break;
      case "0":
      case "#":
      required.push(true);
      break;
      case "9":
      required.push(false);
      break;
      case "A":
      required.push(true);
      break;
      case "a":
      required.push(false);
      break;
      case ">":
      break;
      case "<":
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        required.push(true);
      }
      break;      
      default:
      required.push(false);
      break;
    }
  }
  return required;
}

export function GetModifiersFromMask(mask) {
  let modifiers = [];
  for (let i = 0, p = 0; i < mask.length; i++, p++) {
    switch (mask[i]) {
      case ">":
      modifiers.push({position: p, modifier: ">"});
      p--;
      break;
      case "<":
      modifiers.push({position: p, modifier: "<"});
      p--;
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
      }
      break;      
      default:
      break;
    }
  }
  return modifiers;
}

export function GetStaticCharactersFromMask(mask) {
  let characters = [];
  for (let i = 0, p = 0; i < mask.length; i++, p++) {
    switch (mask[i]) {
      case ">":
      case "<":
      p--;
      break;
      case "\\":
      if (mask.length - 1 > i) {
        i++;
        characters.push({position: p, character: mask[i]})
      }
      break;      
      default:
      break;
    }
  }
  return characters;
}

export function GetTextMatchIsValid(field, value) {
  if (!field.TextMatchType) {
    return true;
  }
  if (!field.Required && !value.length) {
    return true;
  }
  switch (field.TextMatchType) {
    case "Email":
      return ValidateEmail(value);
    default:
      break;
  }
}

export const GetFieldValuesAsString = field => {
  let fieldValuesAsString = null;
  if (field.DisplaySelectionList && field.AllowMultipleValues) {
    fieldValuesAsString = (field.ListValues) ? field.ListValues.map(lv => lv.label).join(", ") : null;
  } else {
    fieldValuesAsString = field.Value;
  }
  return fieldValuesAsString;
}

export const GetFieldHasValueToDisplay = field => {
  return Boolean(GetFieldValuesAsString(field))
    || (field.Type === "FieldType_Image" && field.ImageObjectName !== "");
}

function getValuesAsArray(field) {
  let values = field.Values;
  if (typeof field.Values === "string" && field.Values.length > 0)
    values = JSON.parse(field.Values);
  return values;
}

export function GetDedupedValues(field) {
  let values = field.Values;
  if (field.AllowMultipleValues) {
    values = getValuesAsArray(field);
    if (Array.isArray(values)) {
      let deDupedValues = [];
      values.forEach(original => {
        if (!deDupedValues.filter(deduped => original === deduped).length) {
          deDupedValues.push(original);
        }
      });
      values = deDupedValues;
    }
  }
  return values;
}

export function GetComposedFieldListLabelsAndValues(field) {
  const values = getValuesAsArray(field);
  return (typeof field.AllowMultipleValues !== "undefined" && field.AllowMultipleValues)
    // Multi-select
    // This may need to be updated in the future to use Field.Labels for multi-select fields along with Field.Values.
    // Currently we're using the items in Field.Values for both the label and value of a selection list.
    ? (Array.isArray(values))
      ? values.map((v, i) => (
        { 
          value: v,
          label: (field.ValueLabels && field.ValueLabels.length === values.length)
            ? field.ValueLabels[i]
            : v,
        }
      ))
      : null
    // Single-select
    : (typeof field.Value === "string" && field.Value !== "") 
      ? { 
        value: field.Value,
        label: field.ValueLabel || field.Value,
      }
      : null;
}

export function GetUpdatedFieldObjectForValueChange(field, eventOrValue, selectedOptions) {
  if (typeof field.DisplaySelectionList !== "undefined" && field.DisplaySelectionList && selectedOptions != null) {
    if (field.AllowMultipleValues) {
      field.Values = selectedOptions.map(o => 
        (o.AssetItemTag)
          ? o.AssetItemTag.AssetItemName
          : o.value
      );
      field.ValueLabels = selectedOptions.map(o => o.label);
      field.Value = "";
    } else {
      field.Value = (selectedOptions.AssetItemTag)
        ? selectedOptions.AssetItemTag.AssetItemName
        : selectedOptions.value;
      // Prefer plainlabel over label to avoid issues with persistence
      field.ValueLabel = selectedOptions.plainLabel || selectedOptions.label;
      field.Values = "";
    }      
  } else {
    if (!eventOrValue) {
      field.Value = "";
    } else if (eventOrValue.target !== undefined) {
      field.Value = eventOrValue.target.value;
    } else {
      field.Value = eventOrValue;
    }
    field.Values = "";
  }
  return field;
}

export function GetUpdatedFieldObjectForImageChange(field, imageObjectName, imageSignedUrl) {
  if (!imageObjectName || !imageSignedUrl) {
    field.ImageObjectName = "";
    field.ImageSignedUrl = "";
  } else {
    field.ImageObjectName = imageObjectName;
    field.ImageSignedUrl = imageSignedUrl;
  }
  return field;
}

export const GetDependentFieldsParentField = (field, fields, fieldIdGetter) => {
  const parentFieldId = GetEffectiveParentFieldID(field);
  if (parentFieldId) {
    const parentField = fields.find(f => fieldIdGetter(f) === parentFieldId);
    return parentField;
  }

  return undefined;
}

export const GetDependentFieldsParentValue = (field, fields, fieldIdGetter, parentFieldValueGetterOverride) => {
  const parentFieldId = GetEffectiveParentFieldID(field);
  if (parentFieldId && fields) {
    const parentField = fields.find(f => fieldIdGetter(f) === parentFieldId);
    if (parentField) {
      return parentFieldValueGetterOverride
        ? parentFieldValueGetterOverride(parentField)
        : parentField.Value;
    }
  }

  return undefined;
}

export const HandleGetFieldListItemsFilterPromise = (organizationId, projectId, fieldId, onApiError, 
  forcedSelectionListPromiseFuncID, isOrganizationMember, isProjectMember, parentValue, 
  documentFolderIdForDocumentFolderAssignmentContext, taskIdForTaskAssignmentContext,
  approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext) => valueFilter => {

  if (forcedSelectionListPromiseFuncID) {
    const f = TryGetFilterFieldPromiseFuncById(forcedSelectionListPromiseFuncID);
    if (f) {
      return f(organizationId, projectId, fieldId, valueFilter, isOrganizationMember, isProjectMember);
    }
  }

  let uri;
  if (documentFolderIdForDocumentFolderAssignmentContext) {
    uri = GetUserOrganizationProjectDocumentFolderFieldListItemsPathForApi(organizationId, projectId, 
      documentFolderIdForDocumentFolderAssignmentContext, fieldId);
  } else if (approvalIdForApprovalAssignmentContext) {
    if (assetItemForAssetItemContext) {
      if (taskIdForTaskAssignmentContext) {
        uri = GetUserOrganizationProjectApprovalAssetItemTaskFieldListItemsPathForApi(organizationId, projectId,
          approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, assetItemForAssetItemContext.ID,
          taskIdForTaskAssignmentContext, fieldId);
      } else {
        uri = GetUserOrganizationProjectApprovalAssetItemProjectFieldListItemsPathForApi(organizationId, projectId,
          approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, assetItemForAssetItemContext.ID, fieldId);
      }
    } else if (taskIdForTaskAssignmentContext) {
      uri = GetUserOrganizationProjectApprovalTaskFieldListItemsPathForApi(organizationId, projectId,
        approvalIdForApprovalAssignmentContext, taskIdForTaskAssignmentContext, fieldId);
    }
  } else if (taskIdForTaskAssignmentContext) {
    uri = GetUserOrganizationProjectTaskFieldListItemsPathForApi(organizationId, projectId, 
      taskIdForTaskAssignmentContext, fieldId);
  } else {
    uri = GetFieldListItemsPathForApi(organizationId, projectId, fieldId);
  }

  return API.get(uri,
    { 
      params: {
        valueFilter,
        parentValue,
      }
    }
  )
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      return resp.data.map(item => { return ({ value: item.Value, label: item.Value}) });
    })
    .catch(err => onApiError(err));
}

export const HandleFieldListItemAdd = (organizationId, projectId, fieldId, field, onFieldValueChange, onApiError) => newValue => {
  if (!field.SaveNewSelectionListItems) {
    RouteNewListItemValueToFieldValueChange(fieldId, field, newValue, onFieldValueChange);
    return;
  }

  return API.post(GetFieldListItemsPathForApi(organizationId, projectId, fieldId),
    [{ /*ParentValue: parentValue,*/ Value: newValue }]
  )
    .then(resp => {
      RouteNewListItemValueToFieldValueChange(fieldId, field, newValue, onFieldValueChange);
    })
    .catch(err => onApiError(err));  
}

export function RouteNewListItemValueToFieldValueChange(fieldId, field, newValue, onFieldValueChange) {
  if (field.AllowMultipleValues) {
    let selectedOptions = [];
    if (field.Values) {
      selectedOptions = GetComposedFieldListLabelsAndValues(field);
    }
    selectedOptions.push({ value: newValue, label: newValue });
    onFieldValueChange(fieldId, true)(null, selectedOptions);
  } else {
    onFieldValueChange(fieldId, true)(null, { value: newValue, label: newValue });
  }
}

export function GetMaskedFieldValueIsValid(mask, value) {
    var regex = GetRegexMaskFromMask(mask);
    var required = GetRequiredPositionsFromMask(mask);
    for (let i = 0; i < required.length; i++) {
      if (required[i] && (!value || !value.substr(i, 1).match(regex[i]))) {
        return false;
      }
    }
    return true;
}

export function GetFieldPassesValidation(field) {
  // Reset
  field.RequirementNotMet = false;
  field.InvalidTextMatch = false;
  
  // Masked Fields
  if (field.Type === "FieldType_Text" && field.Mask !== "") {
    if (!field.Required) {
      return true;
    }
    if (!GetMaskedFieldValueIsValid(field.Mask, field.Value)) {
      field.RequirementNotMet = true;
      return false;
    }
  }
  // Multi-select
  else if (field.Type === "FieldType_Text" && field.AllowMultipleValues) {
    if (!field.Required) {
      return true;
    }
    if (!field.Values) {
      field.RequirementNotMet = true;
      return false;
    } else if (Array.isArray(field.Values)) {
      if (!field.Values.length) {
        field.RequirementNotMet = true;
        return false;
      }
    } else if (!field.Values.length) {
      field.RequirementNotMet = true;
      return false
    } else if (!JSON.parse(field.Values).length) {
      field.RequirementNotMet = true;
      return false;
    }
  }
  // Image Type
  else if (field.Type === "FieldType_Image") {
    if (!field.Required) {
      return true;
    }
    if (!field.ImageObjectName) {
      field.RequirementNotMet = true;
      return false;
    }
  }
  // Text Match Type
  else if (field.TextMatchType) {
    if (!GetTextMatchIsValid(field, field.Value)) {
      field.InvalidTextMatch = true;
      field.RequirementNotMet = field.Required;
      return false;
    }
  }
  // All Others
  else {
    if (!field.Required) {
      return true;
    }
    if (!field.Value
      || field.Value.trim() === ""
      || (field.Type === "FieldType_Bool" && field.Value !== "true")) {
      field.RequirementNotMet = true;
      return false;
    }
  }
  return true;
}

export const IsAllowNewSelectionListItemsPossiblyAllowed = (field) => {
  return field
    && field.DisplaySelectionList
    && !field.SelectionListIsDependent;
}

export const GetEffectiveSelectionListIsDependent = (field) => {
  return field.DisplaySelectionList
    && field.SelectionListIsDependent;
}

export const GetEffectiveAllowNewSelectionListItems = (field) => {
  return IsAllowNewSelectionListItemsPossiblyAllowed(field)
    && field.AllowNewSelectionListItems;
}

export const IsSaveNewSelectionListItemsPossiblyAllowed = (field) => {
  return GetEffectiveAllowNewSelectionListItems(field);
}

export const GetEffectiveSaveNewSelectionListItems = (field) => {
  return IsAllowNewSelectionListItemsPossiblyAllowed(field)
    && field.SaveNewSelectionListItems;
}

export const IsAllowMultipleValuesPossiblyAllowed = (field) => {
  return field
    && field.DisplaySelectionList
    && !IsAParentField(field)
    && !field.SelectionListIsDependent;
}

export const IsAParentField = (field) => {
  return Array.isArray(field.ChildFieldIDs) && (field.ChildFieldIDs.length > 0);
}

export const GetEffectiveParentFieldID = (field) => {
  if (field.DisplaySelectionList && field.SelectionListIsDependent) {
      return field.ParentFieldID;
  }
  return "";
}

export const GetEffectiveAllowMultipleValues = (field) => {
  return IsAllowMultipleValuesPossiblyAllowed(field)
    && field.AllowMultipleValues;
}

export const GetDependentFieldParentFieldOptions = (fieldID, fields, isForForms, formTemplateFieldType) => {
  if (!fields) {
    return [];
  }

  const validFieldOptions = getValidDependentFieldParentFields(fieldID, formTemplateFieldType, fields, isForForms);
  
  return validFieldOptions.map(f => {
      return { 
        value: f.ID,
        label: GetEffectiveFieldLabelOrName(f, isForForms),
      };
    });
}

export const GetEffectiveFieldLabelOrName = (f, isForForms) => {
  if (!f) {
    return undefined;
  }

  if (isForForms) {
    if (!f.Field) {
      return f.LabelOrName || f.Label || f.Name || "(unnamed)";
    }
    return f.Field.LabelOrName || f.Field.Label || f.Field.Name || "(unnamed)";
  }
  return f.LabelOrName;
}

function isValidDependentFieldParentField(
  originalChildFieldID, originalChildFieldFormTemplateFieldType,
  field, fields, isForForms
) {
  // If the field is the current field, it's invalid.
  if (field.ID === originalChildFieldID) {
    //console.log(originalFieldBeingEvaledID + ' ' + field.Name + ' field is the current field');
    return false;
  }

  if (isForForms) {
    // Don't allow project fields to be used as a parent for form ListBoxes
    if ((originalChildFieldFormTemplateFieldType === "ListBox") && (field.Type === "Field")) {
      return false;
    }

    field = field.Field;
  }

  // If the field isn't a text field or selection list, it's invalid.
  if ((field.Type !== "FieldType_Text") || (!field.DisplaySelectionList)) {
    //console.log(originalFieldBeingEvaledID + ' ' + field.Name + ' field isn\'t a selection list');
    return false;
  }

  // If the field doesn't have a dependency, it's valid.
  if (!field.SelectionListIsDependent) return true;

  // If the field is dependent on the current field, it's invalid.
  if (field.ParentFieldID === originalChildFieldID) {
    //console.log(originalFieldBeingEvaledID + ' ' + field.Name + ' field is dependent on the current field');
    return false;
  }

  // Recurse on the field's dependency.  If there is no parent then it's valid.
  if (field.ParentFieldID) {
    const parentField = fields.find(f => f.ID === field.ParentFieldID);
    if (parentField) {
      if (!isValidDependentFieldParentField(originalChildFieldID, originalChildFieldFormTemplateFieldType, parentField, fields, isForForms)) {
        //console.log(originalFieldBeingEvaledID + ' ' + field.Name + ' field is (recursively) dependent on the parent field ' + parentField?.Name);
        return false;
      }
    }
  }

  return true;
}

function getValidDependentFieldParentFields(
  originalChildFieldID, originalChildFieldFormTemplateFieldType,
  fields, isForForms
) {
  return fields.filter(field => isValidDependentFieldParentField(
    originalChildFieldID, originalChildFieldFormTemplateFieldType,
    field, fields, isForForms, originalChildFieldFormTemplateFieldType)
  );
}

export const GetFieldMaskOptions = () => {
  return [
    {label: "Phone", value: "(000) 000-0000"},
    {label: "SSN", value: "000-00-0000"},
    {label: "Custom", value: CustomMaskValue},
  ];
}

export const GetFieldTextMatchTypeOptions = () => {
  return [
    {label: "E-mail", value: "Email"},
  ];
}

export const AddMaskType = field => {
  if (!field.Mask)  {
    field.MaskType = "";
  } else {
    let maskOption = GetFieldMaskOptions().filter(o => o.value === field.Mask)[0];
    if (maskOption) {
      field.MaskType = maskOption.value;
    } else {
      field.MaskType = CustomMaskValue;
    }
  }
}

export const GetField = (organizationId, projectId, fieldId) => {
  return API.get(GetFieldPathForApi(organizationId, projectId, fieldId))
    .then(resp => {
      let field = resp.data;
      // Add local MaskType property
      AddMaskType(field);
      return field;
    });
}

export const UpdateField = debounce((organizationId, projectId, field) => {
  return UpdateFieldNoDebounce(organizationId, projectId, field);
}, 500);

export const UpdateFieldNoDebounce = (organizationId, projectId, field) => {
  return API.put(GetFieldPathForApi(organizationId, projectId, field.ID), field);
}

export function SetDefaultOperator(field, forcedOperators) {
  if (forcedOperators && forcedOperators.length) {
    field.Operator = forcedOperators[0].value;
  } else {
    switch (field.Type) {
      case "FieldType_Text":
        field.Operator = TextFieldOperators[0].value;
        break;
      case "FieldType_Bool":
        field.Operator = BoolFieldOperators[0].value;
        break;
      case "FieldType_Date":
      case "FieldType_Number":
      case "FieldType_Currency":
        field.Operator = NumericDateFieldOperators[0].value;
        break;
      default:
        field.Operator = "";
        break;
    }
  } 
}

export function GetFieldHasValue(field) {
  switch (field.Type) {
    case "FieldType_Bool":
      return field.Value === "true";
    default:
      return field.Value.length > 0 || field.Values.length > 0;
  }
}

export function HandleIndexFieldValueChange(fieldID, fields, event, selectedOptions, isSecondary, onSetState) {
  let field = GetUpdatedFieldObjectForValueChange(
    fields.filter(f => f.ID === fieldID)[0],
    event, selectedOptions);
  // Create/Update local HasValue property
  field.HasValue = GetFieldHasValue(field);
  // Set Default Operator if field is empty
  if (!field.HasValue) {
    SetDefaultOperator(field, field.ForcedOperators);
  }
  // ListValues are for selection lists
  field.ListValues = GetComposedFieldListLabelsAndValues(field);
  // Values must exist as a JSON array
  if (Array.isArray(field.Values)) {
    field.Values = JSON.stringify(field.Values);
  }
  if (isSecondary) {
    onSetState({SecondaryFields: fields});
  } else {
    onSetState({Fields: fields});
  }
}

export function HandleIndexFieldOperatorChange(fieldID, fields, operatorValue, onSetState) {
  let field = fields.filter(f => f.ID === fieldID)[0];
  field.Operator = operatorValue;
  onSetState({Fields: fields});
}

export function HandleIndexFieldListItemAdd(field, fields, newValue, onSetState) {
  if (field.AllowMultipleValues) {
    let selectedOptions = [];
    if (field.Values) {
      selectedOptions = GetComposedFieldListLabelsAndValues(field);
    }
    selectedOptions.push({ value: newValue, label: newValue });
    HandleIndexFieldValueChange(field.ID, fields, null, selectedOptions, false, onSetState);
  } else {
    HandleIndexFieldValueChange(field.ID, fields, null, { value: newValue, label: newValue }, false, onSetState);
  }
}

export const GetFilterGridItemsFromFields = (organizationId, projectId_optional,
  isOrganizationMember, isProjectMember, isWorkspace, fields, secondaryFields,
  useSingleColumnLayout, theme, classes, onSetState, onApiError, noBackgroundChange,
  onFieldValueKeyDown, hideHelperText, doNotReorderFields) => {

  if (!fields || !fields.length) {
    return [];
  }

  // Remove any fields that are marked SortOnly
  fields = fields.filter(f => !f.SortOnly);

  // Put fields with values up top
  if (!doNotReorderFields) {
    fields = fields.filter(f => f.HasValue)
      .concat(fields.filter(f => !f.HasValue));
  }

  return fields.map(f => {
    const f2 = secondaryFields.filter(sf => sf.ID === f.ID)[0];

    if (!f.HasValue) {
        SetDefaultOperator(
          f, 
          (isWorkspace && f.ForcedOperatorsForWorkspace)
            ? f.ForcedOperatorsForWorkspace
            : f.ForcedOperators
        );
    }

    // Force basic entry if isWorkspace and ForceBasicTextBoxForWorkspace
    if (isWorkspace && f.ForceBasicTextBoxForWorkspace) {
      f.DisplaySelectionList = false;
      f2.DisplaySelectionList = false;
    }

    let operatorGridItem = null;
    if (f.HasValue) {
      let operatorOptions = [];
      if (isWorkspace && f.ForcedOperatorsForWorkspace) {
        operatorOptions = f.ForcedOperatorsForWorkspace;
      } else if (f.ForcedOperators && f.ForcedOperators.length) {
        operatorOptions = f.ForcedOperators;
      } else {
        switch (f.Type) {
          case "FieldType_Text":
            operatorOptions = TextFieldOperators;
            break;
          case "FieldType_Bool":
            if (f.Value === "true") {
              operatorOptions = BoolFieldOperators;
            }
            break;
          default:
            operatorOptions = NumericDateFieldOperators;
            break;
        }
      }
      if (operatorOptions.length) {
        operatorGridItem = (
          <Grid item
            xs={12}
            md={(useSingleColumnLayout) ? 12 : 4}
            lg={(useSingleColumnLayout) ? 12 : 3}
            xl={(useSingleColumnLayout) ? 12 : 2}
            style={{
              marginTop:theme.spacing(1),
            }}
          >
            <SelectControl label={f.LabelOrName}
              id={`operator_${f.ID}`}
              hideEmpty
              options={operatorOptions} 
              value={f.Operator}
              onValueChange={operatorValue => HandleIndexFieldOperatorChange(f.ID, fields, operatorValue, onSetState)}
            />
          </Grid>
        );
      }
    }

    const primaryFieldGridItem = (
      <GridWithConditionalRendering item
        xs={12}
        md={(!f.HasValue || useSingleColumnLayout) ? 12 : 4}
        lg={(!f.HasValue || useSingleColumnLayout) ? 12 : 3}
        xl={(!f.HasValue || useSingleColumnLayout) ? 12 : 2}
        shouldRender={FieldInput.ShouldRender(f, fields)}
      >
        <FieldInput Field={{...f, Label: null, Name: null, 
            LabelOrName: (f.HasValue && f.Type !== "FieldType_Bool") ? "" : f.LabelOrName
          }}
          fields={
            // Fields property is not currently needed here because any drop-down lists are
            // currently displayed as text boxes by the field filter's property grid
            undefined
          }
          Index={-1}
          UpdateId={new Date()}
          hideHelperText={hideHelperText}
          onValueChange={(event, selectedOptions) => HandleIndexFieldValueChange(f.ID, fields, event, selectedOptions, false, onSetState)}
          onGetSelectionListFilterPromise={HandleGetFieldListItemsFilterPromise(organizationId, projectId_optional, 
            f.FieldID || f.ID, onApiError, f.ForcedSelectionListPromiseFuncID, isOrganizationMember, isProjectMember)}
          onSelectionListItemAdd={newValue => HandleIndexFieldListItemAdd(f, fields, newValue, onSetState)}
          onApiError={onApiError}
          onAlert={() => {}}
          onKeyDown={e => onFieldValueKeyDown(f, e)}
        />
      </GridWithConditionalRendering>
    );

    const isBetweenOperator = (f.Type !== "FieldType_Text" && f.Operator === "betweenAndIncluding");
    const secondaryFieldGridItem = (f.HasValue && isBetweenOperator)
      ? (
      <GridWithConditionalRendering item
        xs={12}
        md={(useSingleColumnLayout) ? 12 : 4}
        lg={(useSingleColumnLayout) ? 12 : 3}
        xl={(useSingleColumnLayout) ? 12 : 2}
        shouldRender={FieldInput.ShouldRender(f, fields)}
      >
        <FieldInput Field={{...f2, LabelOrName: "to"}}
          fields={
            // Fields property is not currently needed here because any drop-down lists are
            // currently displayed as text boxes by the field filter's property grid
            undefined
          }
          Index={-1}
          hideHelperText={hideHelperText}
          onValueChange={(event, selectedOptions) => HandleIndexFieldValueChange(f2.ID, secondaryFields, event, selectedOptions, true, onSetState)}
          onGetSelectionListFilterPromise={HandleGetFieldListItemsFilterPromise(organizationId, projectId_optional,
            f2.FieldID || f2.ID, onApiError)}
          onSelectionListItemAdd={newValue => HandleIndexFieldListItemAdd(f2, secondaryFields, newValue, onSetState)}
          onApiError={onApiError}
          onAlert={() => {}}
          onKeyDown={e => onFieldValueKeyDown(f2, e)}
        />
      </GridWithConditionalRendering>
    ) : null;

    return (
      <Grid item key={f.ID}
        style={{ 
          backgroundColor: (f.HasValue && !noBackgroundChange) ? theme.palette.background.default : undefined,
        }}
        xs={12}
        sm={(f.HasValue || useSingleColumnLayout) ? 12 : 6}
        md={(f.HasValue || useSingleColumnLayout) ? 12 : 4}
        lg={(f.HasValue || useSingleColumnLayout) ? 12 : 3}
        xl={(f.HasValue || useSingleColumnLayout) ? 12 : 2}>
        <Grid container spacing={1}
          className={classes.filterFieldGridItems}
        >
          {operatorGridItem}
          {primaryFieldGridItem}
          {secondaryFieldGridItem}
        </Grid>
      </Grid>
    );
  });
}

export const GetFieldForFilterAndSort = (ID, LabelOrName, Type, ID_for_filter_if_different,
  forcedOperators, forcedSelectionListPromiseFuncID, allowNewSelectionListItems, SortOnly,
  type_for_api_if_different, ID_for_workspace_filter_if_different, forceBasicTextBoxForWorkspace,
  forcedOperatorsForWorkspace, allowMultipleValues) => {

  let field = {
    ID,
    FilterID: ID_for_filter_if_different,
    WorkspaceFilterID: ID_for_workspace_filter_if_different,
    API_Type: type_for_api_if_different,
    LabelOrName,
    Type: Type || "FieldType_Text",
    Value: "",
    SortOnly,
  };
  if (field.Type === "FieldType_Text" && (forcedSelectionListPromiseFuncID || allowMultipleValues)) {
    field.DisplaySelectionList = true;
    field.AllowMultipleValues = allowMultipleValues
    field.AllowNewSelectionListItems = allowNewSelectionListItems;
    field.ForcedSelectionListPromiseFuncID = forcedSelectionListPromiseFuncID;
    field.FormatCreateLabelPrefix = "Use";
  }

  field.ForcedOperators = forcedOperators;
  field.ForceBasicTextBoxForWorkspace = forceBasicTextBoxForWorkspace;
  field.ForcedOperatorsForWorkspace = forcedOperatorsForWorkspace;
  return field;
}

/**
 * Check if the provided item is a plain object.
 * @param {any} item - The item to check.
 * @returns {boolean} - True if the item is a plain object, false otherwise.
 */
const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

/**
 * Compare two plain objects for equality.
 * @param {Object} obj1 - The first object.
 * @param {Object} obj2 - The second object.
 * @returns {boolean} - True if the objects are equal, false otherwise.
 */
const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) return false;

  // Check if every key and value in the first object matches the second object
  return keys1.every((key, index) => {
    const val1 = obj1[key];
    const val2 = obj2[keys2[index]];
    return key === keys2[index] && AreValuesEqual(val1, val2);
  });
};

/**
 * Compare two values (native values, arrays, or plain objects) for equality.
 * @param {any} val1 - The first value.
 * @param {any} val2 - The second value.
 * @returns {boolean} - True if the values are equal, false otherwise.
 */
export const AreValuesEqual = (val1, val2) => {
  // If both values are objects, use the object comparison function
  if (isObject(val1) && isObject(val2)) {
    return areObjectsEqual(val1, val2);
  }

  // If neither value is an array, compare them directly
  if (!Array.isArray(val1) || !Array.isArray(val2)) {
    return val1 === val2;
  }

  // If the arrays have different lengths, they are not equal
  if (val1.length !== val2.length) return false;

  // Sort the arrays and compare each element
  const sortedVal1 = [...val1].sort();
  const sortedVal2 = [...val2].sort();

  return sortedVal1.every((value, index) => AreValuesEqual(value, sortedVal2[index]));
};
