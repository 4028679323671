import axios from 'axios';
import { GetAllFieldsPromise } from './Fields';

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_HOST + "/api/v1/",
});

export default api

export const GetSupportAdminPathForApi = () => {
  return "/supportAdmin";
}

export const GetSupportStatsPathForApi = () => {
  return "/support/stats";
}

export const GetSupportStatOrganizationCountPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/organizationCount`;
}

export const GetSupportStatUserCountPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/userCount`;
}

export const GetSupportStatActiveUsersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/activeUsers`;
}

export const GetSupportStatBillableUsersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/billableUsers`;
}

export const GetSupportStatUserProvidersPathForApi = () => {
  return `${GetSupportStatsPathForApi()}/userProviders`;
}

export const GetSupportUpcomingEventsPathForApi = () => {
  return "/support/upcomingEvents";
}

export const GetSupportUpcomingEventsPublicPathForApi = () => {
  return "/support/upcomingEventsPublic";
}

export const GetSupportOrganizationsPathForApi = () => {
  return "/support/organizations";
}

export const GetSupportOrganizationsRequiringSubscriptionPathForApi = () => {
  return "/support/organizationsRequiringSubscription";
}

export const GetSupportOrganizationPathForApi = organizationId => {
  return `${GetSupportOrganizationsPathForApi()}/${organizationId}`;
}

export const GetSupportUsersPathForApi = () => {
  return "/support/users";
}

export const GetSupportUserActivityPathForApi = () => {
  return "/support/userActivity";
}

export const GetOrganizationMembershipPackagesPathForApi = () => {
  return "/organizationMembershipPackages";
}

export const GetOrganizationsPathForApi = () => {
  return "/organizations";
}

export const GetOrganizationPathForApi = organizationId => {
  return `${GetOrganizationsPathForApi()}/${organizationId}`;
}

export const GetOrganizationDetailPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/detail`;
}

export const GetOrganizationSubscriptionsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/subscriptions`;
}

export const GetOrganizationSubscriptionInvoicesPathForApi = organizationId => {
  return `${GetOrganizationSubscriptionsPathForApi(organizationId)}/invoices`;
}

export const GetOrganizationSubscriptionPlansPathForApi = organizationId => {
  return `${GetOrganizationSubscriptionsPathForApi(organizationId)}/plans`;
}

export const GetOrganizationPermissionsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/permissions`;
}

export const GetProjectMembershipPackagesPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/projectMembershipPackages`;
}

export const GetOrganizationMembersPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/members`;
}

export const GetOrganizationMemberPathForApi = (organizationId, organizationMemberId) => {
  return `${GetOrganizationMembersPathForApi(organizationId)}/${organizationMemberId}`;
}

export const GetOrganizationMemberProjectsPathForApi = (organizationId, organizationMemberId) => {
  return `${GetOrganizationMemberPathForApi(organizationId, organizationMemberId)}/projects`;
}

export const GetOrganizationDashboardWidgetsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/dashboardWidgets`;
}

export const GetOrganizationSearchResultsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/searchResults`;
}

export const GetOrganizationRecentlyAccessedDocumentsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/recentlyAccessedDocuments`;
}

export const GetOrganizationRequestsByMailPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/requestsByMail`;
}

export const GetOrganizationRequestsByMailOnboardingSessionPathForApi = organizationId => {
  return `${GetOrganizationRequestsByMailPathForApi(organizationId)}/onboardingSession`;
}

export const GetOrganizationRequestsByMailSupportPathForApi = organizationId => {
  return `${GetOrganizationRequestsByMailPathForApi(organizationId)}/support`;
}

export const GetOrganizationSearchTemplatesPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/searchTemplates`;
}

export const GetOrganizationSearchTemplatePathForApi = (organizationId, searchTemplateId) => {
  return `${GetOrganizationSearchTemplatesPathForApi(organizationId)}/${searchTemplateId}`;
}

export const GetProjectTemplatesPathForApi = () => {
  return "/projectTemplates";
}

export const GetProjectsPathForApi = organizationId => {
  return `${GetOrganizationPathForApi(organizationId)}/projects`;
}

export const GetProjectPathForApi = (organizationId, projectId) => {
  return `${GetProjectsPathForApi(organizationId)}/${projectId}`;
}

export const GetProjectSearchTemplatesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/searchTemplates`;
}

export const GetProjectSearchTemplatePathForApi = (organizationId, projectId, searchTemplateId) => {
  return `${GetProjectSearchTemplatesPathForApi(organizationId, projectId)}/${searchTemplateId}`;
}

export const GetProjectSettingsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/settings`;
}

export const GetProjectSettingPathForApi = (organizationId, projectId, projectSettingId) => {
  return `${GetProjectSettingsPathForApi(organizationId, projectId)}/${projectSettingId}`;
}

export const GetProjectSettingsPublicPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/settingsPublic`;
}

export const GetProjectMembersPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/members`;
}

export const GetProjectMemberPathForApi = (organizationId, projectId, projectMemberId) => {
  return `${GetProjectMembersPathForApi(organizationId, projectId)}/${projectMemberId}`;
}

export const GetProjectSearchResultsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/searchResults`;
}

export const GetProjectCommentsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/comments`;
}

export const GetDocumentContentPackagesPathForApi = (organizationId, projectId, documentId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentContentPackages/${documentId}`;
}

export const GetDocumentContentPackagesPublicPathForApi = (organizationId, projectId, documentId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentContentPackagesPublic/${documentId}`;
}

export const GetProjectStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/stats`;
}

export const GetTaskSchedulesStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectStatsPathForApi(organizationId, projectId)}/taskSchedules`;
}

export const GetTaskCompletionsStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectStatsPathForApi(organizationId, projectId)}/taskCompletions`;
}

export const GetFormSubmissionsStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectStatsPathForApi(organizationId, projectId)}/formSubmissions`;
}

export const GetSignatureCompletionsStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectStatsPathForApi(organizationId, projectId)}/signatureCompletions`;
}

export const GetSignatureSessionsStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectStatsPathForApi(organizationId, projectId)}/signatureSessions`;
}

export const GetDocumentPackagePathForApi = (organizationId, projectId, documentId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentPackages/${documentId}`;
}

export const GetDocumentPackagePublicPathForApi = (organizationId, projectId, documentId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentPackagesPublic/${documentId}`;
}

export const GetDocumentsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documents`;
}

export const GetDocumentPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentsPathForApi(organizationId, projectId)}/${documentId}`;
}

export const GetDocumentFieldsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/fields`;
}

export const GetDocumentFieldPathForApi = (organizationId, projectId, documentId, fieldId) => {
  return `${GetDocumentFieldsPathForApi(organizationId, projectId, documentId)}/${fieldId}`;
}

export const GetDocumentFieldImageUploadsPathForApi = (organizationId, projectId, documentId, fieldId) => {
  return `${GetDocumentFieldPathForApi(organizationId, projectId, documentId, fieldId)}/imageUploads`;
}

export const GetDocumentsPublicPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentsPublic`;
}

export const GetDocumentSearchResultsPublicPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentSearchResultsPublic`;
}

export const GetDocumentCommentsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/comments`;
}

export const GetCommentSuggestionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/commentSuggestions`;
}

export const GetDocumentThumbnailsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/thumbnails`;
}

export const GetDocumentEventsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/events`;
}

export const GetDocumentFilterSortFieldsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentFilterSortFields`;
}

export const GetDocumentFilterSortFieldsPublicPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentFilterSortFieldsPublic`;
}

export const GetDocumentActionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentActions`;
}

export const GetDocumentRevisionsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/revisions`;
}

export const GetDocumentRevisionPathForApi = (organizationId, projectId, documentId, documentRevisionId) => {
  return `${GetDocumentRevisionsPathForApi(organizationId, projectId, documentId)}/${documentRevisionId}`;
}

export const GetDocumentRevisionContentPathForApi = (organizationId, projectId, documentId, documentRevisionId) => {
  return `${GetDocumentRevisionPathForApi(organizationId, projectId, documentId, documentRevisionId)}/content`;
}

export const GetDocumentRevisionContentRestorePathForApi = (organizationId, projectId, documentId, documentRevisionId) => {
  return `${GetDocumentRevisionPathForApi(organizationId, projectId, documentId, documentRevisionId)}/contentRestore`;
}

export const GetTaskActionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/taskActions`;
}

export const GetApprovalActionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/approvalActions`;
}

export const GetOrganizationDocumentSubscriptionsPathForApi = (organizationId) => {
  return `${GetOrganizationPathForApi(organizationId)}/documentSubscriptions`;
}

export const GetProjectDocumentSubscriptionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentSubscriptions`;
}

export const GetProjectDocumentSubscriptionPathForApi = (organizationId, projectId, documentSubscriptionId) => {
  return `${GetProjectDocumentSubscriptionsPathForApi(organizationId, projectId)}/${documentSubscriptionId}`;
}

export const GetDocumentSignatureSessionRecipientsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentSignatureSessionRecipients`;
}

export const GetDocumentUploadsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentUploads`;
}

export const GetDocumentUploadFieldsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentUploadFields`;
}

export const GetDocumentUploadFieldPathForApi = (organizationId, projectId, fieldId) => {
  return `${GetDocumentUploadFieldsPathForApi(organizationId, projectId)}/${fieldId}`;
}

export const GetDocumentUploadFieldImageUploadsPathForApi = (organizationId, projectId, fieldId) => {
  return `${GetDocumentUploadFieldPathForApi(organizationId, projectId, fieldId)}/imageUploads`;
}

export const GetTaskMilestonesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/taskMilestones`;
}

export const GetTaskMilestonePathForApi = (organizationId, projectId, taskMilestoneId) => {
  return `${GetTaskMilestonesPathForApi(organizationId, projectId)}/${taskMilestoneId}`;
}

export const GetTaskStatesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/taskStates`;
}

export const GetTaskStatePathForApi = (organizationId, projectId, taskStateId) => {
  return `${GetTaskStatesPathForApi(organizationId, projectId)}/${taskStateId}`;
}

export const GetTaskStatsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/taskStats`;
}

export const GetTasksPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/tasks`;
}

export const GetTaskPathForApi = (organizationId, projectId, taskId) => {
  return `${GetTasksPathForApi(organizationId, projectId)}/${taskId}`;
}

export const GetTaskCommentsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetTaskPathForApi(organizationId, projectId, taskId)}/comments`;
}

export const GetTaskDocumentsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetTaskPathForApi(organizationId, projectId, taskId)}/documents`;
}

export const GetCompletedTasksPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/completedTasks`;
}

export const GetCompletedTaskPathForApi = (organizationId, projectId, taskId) => {
  return `${GetCompletedTasksPathForApi(organizationId, projectId)}/${taskId}`;
}

export const GetDeniedTasksPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/deniedTasks`;
}

export const GetDeniedTaskPathForApi = (organizationId, projectId, taskId) => {
  return `${GetDeniedTasksPathForApi(organizationId, projectId)}/${taskId}`;
}

export const GetTagsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/tags`;
}

export const GetTagPathForApi = (organizationId, projectId, tagId) => {
  return `${GetTagsPathForApi(organizationId, projectId)}/${tagId}`;
}

export const GetApprovalsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/approvals`;
}

export const GetApprovalPathForApi = (organizationId, projectId, approvalId) => {
  return `${GetApprovalsPathForApi(organizationId, projectId)}/${approvalId}`;
}

export const GetRecycleBinDocumentsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/recycleBinDocuments`;
}

export const GetDocumentFoldersPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentFolders`;
}

export const GetDocumentFolderPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetDocumentFoldersPathForApi(organizationId, projectId)}/${documentFolderId}`;
}

export const GetDocumentFoldersPublicPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/documentFoldersPublic`;
}

export const GetFieldsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/fields`;
}

export const GetFieldPathForApi = (organizationId, projectId, fieldId) => {
  return `${GetFieldsPathForApi(organizationId, projectId)}/${fieldId}`;
}

export const GetSensitiveFieldsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/sensitiveFields`;
}

export const GetFieldListItemsPathForApi = (organizationId, projectId, fieldId) => {
  return `${GetFieldPathForApi(organizationId, projectId, fieldId)}/listItems`;
}

export const GetAssetsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/assets`;
}

export const GetAssetPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetsPathForApi(organizationId, projectId)}/${assetId}`;
}

export const GetAssetFilterSortFieldsPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetPathForApi(organizationId, projectId, assetId)}/assetFilterSortFields`;
}

export const GetAssetFieldIDsPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetPathForApi(organizationId, projectId, assetId)}/fieldIDs`;
}

export const GetAssetItemsPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetPathForApi(organizationId, projectId, assetId)}/assetItems`;
}

export const GetProjectAssetItemsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/assetItems`;
}

export const GetAssetItemFieldsByAssetPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetPathForApi(organizationId, projectId, assetId)}/assetItemFields`;
}

export const GetAssetItemPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemsPathForApi(organizationId, projectId, assetId)}/${assetItemId}`;
}

export const GetAssetItemFieldsPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/assetItemFields`;
}

export const GetAssetItemFieldsForImagePathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/fields`;
}

export const GetAssetItemFieldForImagePathForApi = (organizationId, projectId, assetId, assetItemId, fieldId) => {
  return `${GetAssetItemFieldsForImagePathForApi(organizationId, projectId, assetId, assetItemId)}/${fieldId}`;
}

export const GetAssetItemFieldImageUploadsPathForApi = (organizationId, projectId, assetId, assetItemId, fieldId) => {
  return `${GetAssetItemFieldForImagePathForApi(organizationId, projectId, assetId, assetItemId, fieldId)}/imageUploads`;
}

export const GetAssetItemDocumentsPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/documents`;
}

export const GetAssetItemTasksPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/tasks`;
}

export const GetAssetItemAssetItemsPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/assetItems`;
}

export const GetAssetItemCommentsPathForApi = (organizationId, projectId, assetId, assetItemId) => {
  return `${GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId)}/comments`;
}

export const GetNewAssetItemPathForApi = (organizationId, projectId, assetId) => {
  return `${GetAssetItemsPathForApi(organizationId, projectId, assetId)}/newAssetItem`;
}

export const GetNewAssetItemFieldsForImagePathForApi = (organizationId, projectId, assetId) => {
  return `${GetNewAssetItemPathForApi(organizationId, projectId, assetId)}/fields`;
}

export const GetNewAssetItemFieldForImagePathForApi = (organizationId, projectId, assetId, fieldId) => {
  return `${GetNewAssetItemFieldsForImagePathForApi(organizationId, projectId, assetId)}/${fieldId}`;
}

export const GetNewAssetItemFieldImageUploadsPathForApi = (organizationId, projectId, assetId, fieldId) => {
  return `${GetNewAssetItemFieldForImagePathForApi(organizationId, projectId, assetId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationsPathForApi = () => {
  return `${GetUserPathForApi()}/organizations`;
}

export const GetUserOrganizationPathForApi = organizationId => {
  return `${GetUserOrganizationsPathForApi()}/${organizationId}`;
}

export const GetUserOrganizationNotificationsPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/notifications`;
}

export const GetUserOrganizationNotificationActionsPathForApi = organizationId => {
  return `${GetUserOrganizationNotificationsPathForApi(organizationId)}/actions`;
}

export const GetUserHasUnreadOrganizationNotificationsPathForApi = organizationId => {
  return `${GetUserOrganizationNotificationsPathForApi(organizationId)}/hasUnread`;
}

export const GetUserOrganizationTasksPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/tasks`;
}

export const GetUserOrganizationApprovalsPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/approvals`;
}

export const GetUserOrganizationDocumentFoldersPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/documentFolders`;
}

export const GetUserOrganizationSearchResultsPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/searchResults`;
}

export const GetUserOrganizationProjectsPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/projects`;
}

export const GetUserOrganizationProjectPathForApi = (organizationId, projectId) => {
  return `${GetUserOrganizationProjectsPathForApi(organizationId)}/${projectId}`;
}

export const GetUserOrganizationProjectTaskPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/tasks/${taskId}`;
}

export const GetUserOrganizationProjectTaskFieldsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/fields`;
}

export const GetUserOrganizationProjectTaskFieldPathForApi = (organizationId, projectId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectTaskFieldsPathForApi(organizationId, projectId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectTaskFieldListItemsPathForApi = (organizationId, projectId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectTaskFieldPathForApi(organizationId, projectId, taskId, fieldId)}/listItems`;
}

export const GetUserOrganizationProjectTaskUploadsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/documentUploads`;
}

export const GetUserOrganizationProjectTaskUploadFieldsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/documentUploadFields`;
}

export const GetUserOrganizationProjectTaskUploadFieldPathForApi = (organizationId, projectId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectTaskUploadFieldsPathForApi(organizationId, projectId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectTaskUploadFieldImageUploadsPathForApi = (organizationId, projectId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectTaskUploadFieldPathForApi(organizationId, projectId, taskId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationProjectTaskImageUploadSessionsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/imageUploadSessions`;
}

export const GetUserOrganizationProjectTaskImageUploadSessionPathForApi = (organizationId, projectId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectTaskImageUploadSessionsPathForApi(organizationId, projectId, taskId)}/${imageUploadSessionId}`;
}

export const GetUserOrganizationProjectTaskImageUploadSessionImagesPathForApi = (organizationId, projectId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectTaskImageUploadSessionPathForApi(organizationId, projectId, taskId, imageUploadSessionId)}/images`;
}

export const GetUserOrganizationProjectTaskMilestonesPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/taskMilestones`;
}

export const GetUserOrganizationProjectTaskStatesPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/taskStates`;
}

export const GetUserOrganizationProjectTaskDocumentsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/documents`;
}

export const GetUserOrganizationProjectTaskDocumentPathForApi = (organizationId, projectId, taskId, documentId) => {
  return `${GetUserOrganizationProjectTaskDocumentsPathForApi(organizationId, projectId, taskId)}/${documentId}`;
}

export const GetUserOrganizationProjectTaskDocumentPackagesPathForApi = (organizationId, projectId, taskId, documentId) => {
  return `${GetUserOrganizationProjectTaskDocumentPathForApi(organizationId, projectId, taskId, documentId)}/packages`;
}

export const GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi = (organizationId, projectId, taskId, documentId) => {
  return `${GetUserOrganizationProjectTaskDocumentPathForApi(organizationId, projectId, taskId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectTaskDocumentThumbnailsPathForApi = (organizationId, projectId, taskId, documentId) => {
  return `${GetUserOrganizationProjectTaskDocumentPathForApi(organizationId, projectId, taskId, documentId)}/thumbnails`;
}

export const GetUserOrganizationProjectTaskDocumentCommentsPathForApi = (organizationId, projectId, taskId, documentId) => {
  return `${GetUserOrganizationProjectTaskDocumentPathForApi(organizationId, projectId, taskId, documentId)}/comments`;
}

export const GetUserOrganizationProjectTaskCommentsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/comments`;
}

export const GetUserOrganizationProjectTaskTagsPathForApi = (organizationId, projectId, taskId) => {
  return `${GetUserOrganizationProjectTaskPathForApi(organizationId, projectId, taskId)}/tags`;
}

export const GetUserOrganizationProjectTaskActionsPathForApi = (organizationId, projectId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/taskActions`;
}

export const GetUserOrganizationProjectDocumentFoldersPathForApi = (organizationId, projectId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/documentFolders`;
}

export const GetUserOrganizationProjectDocumentFolderPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFoldersPathForApi(organizationId, projectId)}/${documentFolderId}`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentFilterSortFieldsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/documentFilterSortFields`;
}

export const GetUserOrganizationProjectDocumentFolderFieldsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/fields`;
}

export const GetUserOrganizationProjectDocumentFolderFieldPathForApi = (organizationId, projectId, documentFolderId, fieldId) => {
  return `${GetUserOrganizationProjectDocumentFolderFieldsPathForApi(organizationId, projectId, documentFolderId)}/${fieldId}`;
}

export const GetUserOrganizationProjectDocumentFolderFieldListItemsPathForApi = (organizationId, projectId, documentFolderId, fieldId) => {
  return `${GetUserOrganizationProjectDocumentFolderFieldPathForApi(organizationId, projectId, documentFolderId, fieldId)}/listItems`;
}

export const GetUserOrganizationProjectDocumentFolderUploadsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/documentUploads`;
}

export const GetUserOrganizationProjectDocumentFolderUploadFieldsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/documentUploadFields`;
}

export const GetUserOrganizationProjectDocumentFolderUploadFieldPathForApi = (organizationId, projectId, documentFolderId, fieldId) => {
  return `${GetUserOrganizationProjectDocumentFolderUploadFieldsPathForApi(organizationId, projectId, documentFolderId)}/${fieldId}`;
}

export const GetUserOrganizationProjectDocumentFolderUploadFieldImageUploadsPathForApi = (organizationId, projectId, documentFolderId, fieldId) => {
  return `${GetUserOrganizationProjectDocumentFolderUploadFieldPathForApi(organizationId, projectId, documentFolderId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationProjectDocumentFolderImageUploadSessionsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/imageUploadSessions`;
}

export const GetUserOrganizationProjectDocumentFolderImageUploadSessionPathForApi = (organizationId, projectId, documentFolderId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectDocumentFolderImageUploadSessionsPathForApi(organizationId, projectId, documentFolderId)}/${imageUploadSessionId}`;
}

export const GetUserOrganizationProjectDocumentFolderImageUploadSessionImagesPathForApi = (organizationId, projectId, documentFolderId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectDocumentFolderImageUploadSessionPathForApi(organizationId, projectId, documentFolderId, imageUploadSessionId)}/images`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentsPathForApi = (organizationId, projectId, documentFolderId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/documents`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentPathForApi = (organizationId, projectId, documentFolderId, documentId) => {
  return `${GetUserOrganizationProjectDocumentFolderPathForApi(organizationId, projectId, documentFolderId)}/documents/${documentId}`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentPackagesPathForApi = (organizationId, projectId, documentFolderId, documentId) => {
  return `${GetUserOrganizationProjectDocumentFolderDocumentPathForApi(organizationId, projectId, documentFolderId, documentId)}/packages`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentContentPackagesPathForApi = (organizationId, projectId, documentFolderId, documentId) => {
  return `${GetUserOrganizationProjectDocumentFolderDocumentPathForApi(organizationId, projectId, documentFolderId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectDocumentFolderDocumentCommentsPathForApi = (organizationId, projectId, documentFolderId, documentId) => {
  return `${GetUserOrganizationProjectDocumentFolderDocumentPathForApi(organizationId, projectId, documentFolderId, documentId)}/comments`;
}

export const GetUserOrganizationDocumentSignatureSessionRecipientsPathForApi = organizationId => {
  return `${GetUserOrganizationPathForApi(organizationId)}/documentSignatureSessionRecipients`;
}

export const GetUserOrganizationProjectDocumentSignatureSessionRecipientDocumentContentPackagesPathForApi = (organizationId, projectId, documentSignatureSessionRecipientId, documentSignatureSessionId, documentId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/documentSignatureSessionRecipients/${documentSignatureSessionRecipientId}/documents/${documentId}/contentPackages?documentSignatureSessionId=${documentSignatureSessionId}`;
}

export const GetUserOrganizationProjectApprovalPathForApi = (organizationId, projectId, approvalId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/approvals/${approvalId}`;
}

export const GetUserOrganizationProjectApprovalTaskPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalPathForApi(organizationId, projectId, approvalId)}/tasks/${taskId}`;
}

export const GetUserOrganizationProjectApprovalTaskImageUploadSessionsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/imageUploadSessions`;
}

export const GetUserOrganizationProjectApprovalTaskImageUploadSessionPathForApi = (organizationId, projectId, approvalId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalTaskImageUploadSessionsPathForApi(organizationId, projectId, approvalId, taskId)}/${imageUploadSessionId}`;
}

export const GetUserOrganizationProjectApprovalTaskImageUploadSessionImagesPathForApi = (organizationId, projectId, approvalId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalTaskImageUploadSessionPathForApi(organizationId, projectId, approvalId, taskId, imageUploadSessionId)}/images`;
}

export const GetUserOrganizationProjectApprovalTaskFieldsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/fields`;
}

export const GetUserOrganizationProjectApprovalTaskFieldPathForApi = (organizationId, projectId, approvalId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalTaskFieldsPathForApi(organizationId, projectId, approvalId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalTaskFieldListItemsPathForApi = (organizationId, projectId, approvalId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalTaskFieldPathForApi(organizationId, projectId, approvalId, taskId, fieldId)}/listItems`;
}

export const GetUserOrganizationProjectApprovalTaskUploadsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/documentUploads`;
}

export const GetUserOrganizationProjectApprovalTaskUploadFieldsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/documentUploadFields`;
}

export const GetUserOrganizationProjectApprovalTaskUploadFieldPathForApi = (organizationId, projectId, approvalId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalTaskUploadFieldsPathForApi(organizationId, projectId, approvalId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalTaskUploadFieldImageUploadsPathForApi = (organizationId, projectId, approvalId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalTaskUploadFieldPathForApi(organizationId, projectId, approvalId, taskId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationProjectApprovalTaskCommentsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/comments`;
}

export const GetUserOrganizationProjectApprovalTaskTagsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/tags`;
}

export const GetUserOrganizationProjectApprovalTaskMilestonesPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/taskMilestones`;
}

export const GetUserOrganizationProjectApprovalTaskStatesPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/taskStates`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentsPathForApi = (organizationId, projectId, approvalId, taskId) => {
  return `${GetUserOrganizationProjectApprovalTaskPathForApi(organizationId, projectId, approvalId, taskId)}/documents`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentPathForApi = (organizationId, projectId, approvalId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalTaskDocumentsPathForApi(organizationId, projectId, approvalId, taskId)}/${documentId}`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentPackagesPathForApi = (organizationId, projectId, approvalId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalTaskDocumentPathForApi(organizationId, projectId, approvalId, taskId, documentId)}/packages`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentContentPackagesPathForApi = (organizationId, projectId, approvalId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalTaskDocumentPathForApi(organizationId, projectId, approvalId, taskId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentCommentsPathForApi = (organizationId, projectId, approvalId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalTaskDocumentPathForApi(organizationId, projectId, approvalId, taskId, documentId)}/comments`;
}

export const GetUserOrganizationProjectApprovalTaskDocumentThumbnailsPathForApi = (organizationId, projectId, approvalId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalTaskDocumentPathForApi(organizationId, projectId, approvalId, taskId, documentId)}/thumbnails`;
}

export const GetUserOrganizationProjectApprovalAssetPathForApi = (organizationId, projectId, approvalId, assetId) => {
  return `${GetUserOrganizationProjectApprovalPathForApi(organizationId, projectId, approvalId)}/assets/${assetId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetPathForApi(organizationId, projectId, approvalId, assetId)}/assetItems/${assetItemId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemCommentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/comments`;
}

export const GetUserOrganizationProjectApprovalAssetItemProjectFieldsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/fields`;
}

export const GetUserOrganizationProjectApprovalAssetItemProjectFieldPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemProjectFieldsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemProjectFieldListItemsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemProjectFieldPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, fieldId)}/listItems`;
}

export const GetUserOrganizationProjectApprovalAssetItemUploadsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/documentUploads`;
}

export const GetUserOrganizationProjectApprovalAssetItemUploadFieldsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/documentUploadFields`;
}

export const GetUserOrganizationProjectApprovalAssetItemUploadFieldPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemUploadFieldsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemUploadFieldImageUploadsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemUploadFieldPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationProjectApprovalAssetItemFieldsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/assetItemFields`;
}

export const GetUserOrganizationProjectApprovalAssetItemImageUploadSessionsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/imageUploadSessions`;
}

export const GetUserOrganizationProjectApprovalAssetItemImageUploadSessionPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemImageUploadSessionsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/${imageUploadSessionId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemImageUploadSessionImagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemImageUploadSessionPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, imageUploadSessionId)}/images`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/documents`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemDocumentsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/${documentId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentPackagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, documentId)}/packages`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentContentPackagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentThumbnailsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, documentId)}/thumbnails`;
}

export const GetUserOrganizationProjectApprovalAssetItemDocumentCommentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, documentId)}/comments`;
}

export const GetUserOrganizationProjectApprovalAssetItemTasksPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/tasks`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTasksPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/${taskId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskFieldsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/fields`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskFieldPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskFieldsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskFieldListItemsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskFieldPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId)}/listItems`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskUploadsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/documentUploads`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/documentUploadFields`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/${fieldId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldImageUploadsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, fieldId)}/imageUploads`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/imageUploadSessions`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/${imageUploadSessionId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionImagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, imageUploadSessionId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, imageUploadSessionId)}/images`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskCommentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/comments`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/documents`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskDocumentsPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId)}/${documentId}`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentPackagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId)}/packages`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentContentPackagesPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentThumbnailsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId)}/thumbnails`;
}

export const GetUserOrganizationProjectApprovalAssetItemTaskDocumentCommentsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemTaskDocumentPathForApi(organizationId, projectId, approvalId, assetId, assetItemId, taskId, documentId)}/comments`;
}

export const GetUserOrganizationProjectApprovalAssetItemAssetItemsPathForApi = (organizationId, projectId, approvalId, assetId, assetItemId) => {
  return `${GetUserOrganizationProjectApprovalAssetItemPathForApi(organizationId, projectId, approvalId, assetId, assetItemId)}/assetItems`;
}

export const GetUserOrganizationProjectApprovalDocumentPathForApi = (organizationId, projectId, approvalId, documentId) => {
  return `${GetUserOrganizationProjectApprovalPathForApi(organizationId, projectId, approvalId)}/documents/${documentId}`;
}

export const GetUserOrganizationProjectApprovalDocumentPackagesPathForApi = (organizationId, projectId, approvalId, documentId) => {
  return `${GetUserOrganizationProjectApprovalDocumentPathForApi(organizationId, projectId, approvalId, documentId)}/packages`;
}

export const GetUserOrganizationProjectApprovalDocumentContentPackagesPathForApi = (organizationId, projectId, approvalId, documentId) => {
  return `${GetUserOrganizationProjectApprovalDocumentPathForApi(organizationId, projectId, approvalId, documentId)}/contentPackages`;
}

export const GetUserOrganizationProjectApprovalDocumentCommentsPathForApi = (organizationId, projectId, approvalId, documentId) => {
  return `${GetUserOrganizationProjectApprovalDocumentPathForApi(organizationId, projectId, approvalId, documentId)}/comments`;
}

export const GetUserOrganizationProjectApprovalDocumentThumbnailsPathForApi = (organizationId, projectId, approvalId, documentId) => {
  return `${GetUserOrganizationProjectApprovalDocumentPathForApi(organizationId, projectId, approvalId, documentId)}/thumbnails`;
}

export const GetUserOrganizationProjectApprovalActionsPathForApi = (organizationId, projectId) => {
  return `${GetUserOrganizationProjectPathForApi(organizationId, projectId)}/approvalActions`;
}

export const GetUserPathForApi = () => {
  return "/user";
}

export const GetUserAddressBookItemsPathForApi = () => {
  return `${GetUserPathForApi()}/addressBookItems`;
}

export const GetUserSignupPathForApi = () => {
  return `${GetUserPathForApi()}/signup`;
}

export const GetUserPreferencesPathForApi = () => {
  return `${GetUserPathForApi()}/preferences`;
}

export const GetUserProfilePathForApi = () => {
  return `${GetUserPathForApi()}/profile`;
}

export const GetUserAvatarsPathForApi = () => {
  return `${GetUserPathForApi()}/avatars`;
}

export const GetUserApiKeysPathForApi = () => {
  return `${GetUserPathForApi()}/apiKeys`;
}

export const GetUserApiKeyPathForApi = userApiKeyId => {
  return `${GetUserApiKeysPathForApi()}/${userApiKeyId}`;
}

export const GetDocumentSignatureSessionPackagesPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/signatureSessionPackages`;
}

export const GetDocumentSignatureFormsPathForApi = (organizationId, projectId, documentId) => {
  return `${GetDocumentPathForApi(organizationId, projectId, documentId)}/signatureForms`;
}

export const GetDocumentSignatureFormPathForApi = (organizationId, projectId, documentId, signatureFormId) => {
  return `${GetDocumentSignatureFormsPathForApi(organizationId, projectId, documentId)}/${signatureFormId}`;
}

export const GetDocumentSignatureFormFieldsPathForApi = (organizationId, projectId, documentId, signatureFormId) => {
  return `${GetDocumentSignatureFormPathForApi(organizationId, projectId, documentId, signatureFormId)}/fields`;
}

export const GetDocumentSignatureFormFieldPathForApi = (organizationId, projectId, documentId, signatureFormId, fieldId) => {
  return `${GetDocumentSignatureFormFieldsPathForApi(organizationId, projectId, documentId, signatureFormId)}/${fieldId}`;
}

export const GetSignatureFormTemplatesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/signatureFormTemplates`;
}

export const GetSignatureFormTemplatePathForApi = (organizationId, projectId, signatureFormTemplateId) => {
  return `${GetSignatureFormTemplatesPathForApi(organizationId, projectId)}/${signatureFormTemplateId}`;
}

export const GetSignatureFormTemplateFieldsPathForApi = (organizationId, projectId, signatureFormTemplateId) => {
  return `${GetSignatureFormTemplatePathForApi(organizationId, projectId, signatureFormTemplateId)}/fields`;
}

export const GetRecentDocumentSignatureFormsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/recentDocumentSignatureForms`;
}

export const GetImageUploadSessionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/imageUploadSessions`;
}

export const GetImageUploadSessionPathForApi = (organizationId, projectId, imageUploadSessionId) => {
  return `${GetImageUploadSessionsPathForApi(organizationId, projectId)}/${imageUploadSessionId}`;
}

export const GetImageUploadSessionImagesPathForApi = (organizationId, projectId, imageUploadSessionId) => {
  return `${GetImageUploadSessionPathForApi(organizationId, projectId, imageUploadSessionId)}/images`;
}

export const GetFileDownloadsPathForApi = () => {
  return `/fileDownloads`;
}

export const GetPublicVideoPath = filename => {
  return `https://storage.googleapis.com/${
      process.env.REACT_APP_GOOGLE_PROJECTID}-videos/${filename}`;
}

export const GetPublicAvatarImagePathForApi = userEmail => {
  return `/users/${userEmail}/avatar`;
}

export const GetPublicUserProfilePathForApi = userEmail => {
  return `/users/${userEmail}/profile`;
}

export const GetProcessesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/processes`;
}

export const GetProcessPathForApi = (organizationId, projectId, processId) => {
  return `${GetProcessesPathForApi(organizationId, projectId)}/${processId}`;
}

export const GetProcessElementsPathForApi = (organizationId, projectId, processId) => {
  return `${GetProcessPathForApi(organizationId, projectId, processId)}/processElements`;
}

export const GetProcessElementPathForApi = (organizationId, projectId, processId, processElementId) => {
  return `${GetProcessElementsPathForApi(organizationId, projectId, processId)}/${processElementId}`;
}

export const GetProcessElementConnectionsPathForApi = (organizationId, projectId, processId, processElementId) => {
  return `${GetProcessElementPathForApi(organizationId, projectId, processId, processElementId)}/processElementConnections`;
}

export const GetProcessElementConnectionPathForApi = (organizationId, projectId, processId, processElementId, processElementConnectionId) => {
  return `${GetProcessElementConnectionsPathForApi(organizationId, projectId, processId, processElementId)}/${processElementConnectionId}`;
}

export const GetFormTemplateSourceUploadsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/formTemplateSourceUploads`;
}

export const GetFormTemplatesPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/formTemplates`;
}

export const GetFormTemplatePathForApi = (organizationId, projectId, formTemplateId) => {
  return `${GetFormTemplatesPathForApi(organizationId, projectId)}/${formTemplateId}`;
}

export const GetFormTemplateEventsPathForApi = (organizationId, projectId, formTemplateId) => {
  return `${GetFormTemplatePathForApi(organizationId, projectId, formTemplateId)}/events`;
}

export const GetFormTemplateEventPathForApi = (organizationId, projectId, formTemplateId, eventId) => {
  return `${GetFormTemplateEventsPathForApi(organizationId, projectId, formTemplateId)}/${eventId}`;
}

export const GetFormTemplateFieldsPathForApi = (organizationId, projectId, formTemplateId) => {
  return `${GetFormTemplatePathForApi(organizationId, projectId, formTemplateId)}/fields`;
}

export const GetProjectFormTemplateFieldsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/formTemplateFields`;
}

export const GetFormTemplateFieldPathForApi = (organizationId, projectId, formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplateFieldsPathForApi(organizationId, projectId, formTemplateId)}/${formTemplateFieldId}`;
}

export const GetFormTemplateFieldImagesPathForApi = (organizationId, projectId, formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplateFieldPathForApi(organizationId, projectId, formTemplateId, formTemplateFieldId)}/images`;
}

export const GetFormTemplatePagesPathForApi = (organizationId, projectId, formTemplateId) => {
  return `${GetFormTemplatePathForApi(organizationId, projectId, formTemplateId)}/pages`;
}

export const GetFormTemplatePagePathForApi = (organizationId, projectId, formTemplateId, pageIndex) => {
  return `${GetFormTemplatePagesPathForApi(organizationId, projectId, formTemplateId)}/${pageIndex}`;
}

export const GetFormTemplatePageImagesPathForApi = (organizationId, projectId, formTemplateId, pageIndex) => {
  return `${GetFormTemplatePagePathForApi(organizationId, projectId, formTemplateId, pageIndex)}/images`;
}

export const GetFormTemplatesPublicPathForApi = () => {
  return `/formTemplatesPublic`;
}

export const GetFormTemplatePublicPathForApi = (formTemplateId) => {
  return `${GetFormTemplatesPublicPathForApi()}/${formTemplateId}`;
}

export const GetFormTemplatesPublicFieldsPathForApi = (formTemplateId) => {
  return `${GetFormTemplatePublicPathForApi(formTemplateId)}/fields`;
}

export const GetFormTemplatesPublicFieldPathForApi = (formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplatesPublicFieldsPathForApi(formTemplateId)}/${formTemplateFieldId}`;
}

export const GetFormTemplatesPublicFieldListItemsPathForApi = (formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplatesPublicFieldPathForApi(formTemplateId, formTemplateFieldId)}/listItems`;
}

export const GetFormTemplatesPublicFieldDocumentUploadsPathForApi = (formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplatesPublicFieldPathForApi(formTemplateId, formTemplateFieldId)}/documentUploads`;
}

export const GetFormTemplatesPublicFieldImageUploadSessionsPathForApi = (formTemplateId, formTemplateFieldId) => {
  return `${GetFormTemplatesPublicFieldPathForApi(formTemplateId, formTemplateFieldId)}/imageUploadSessions`;
}

export const GetFormTemplatesPublicFieldImageUploadSessionPathForApi = (formTemplateId, formTemplateFieldId, imageUploadSessionId) => {
  return `${GetFormTemplatesPublicFieldImageUploadSessionsPathForApi(formTemplateId, formTemplateFieldId)}/${imageUploadSessionId}`;
}

export const GetFormTemplatesPublicFieldImageUploadSessionImagesPathForApi = (formTemplateId, formTemplateFieldId, imageUploadSessionId) => {
  return `${GetFormTemplatesPublicFieldImageUploadSessionPathForApi(formTemplateId, formTemplateFieldId, imageUploadSessionId)}/images`;
}

export const GetFormTemplatesPublicSubmissionsPathForApi = (formTemplateId) => {
  return `${GetFormTemplatePublicPathForApi(formTemplateId)}/submissions`;
}

export const GetFormTemplateActionsPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/formTemplateActions`;
}

const getDocumentsCountPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/counts/documents`;
}

export const GetDocumentsCount = (organizationId, projectId, ignoreRecycleBin) => {
	if (!ignoreRecycleBin) {
		ignoreRecycleBin = false;
	}
  return api.get(getDocumentsCountPathForApi(organizationId, projectId),
    { params: { ignoreRecycleBin: ignoreRecycleBin }})
    .then(resp => { 
      return resp.data;
    });
}

const getRecycleBinDocumentsCountPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/counts/recycleBinDocuments`;
}

export const GetRecycleBinDocumentsCount = (organizationId, projectId) => {
  return api.get(getRecycleBinDocumentsCountPathForApi(organizationId, projectId))
    .then(resp => { 
      return resp.data
    });
}

const getPageCountPathForApi = (organizationId, projectId) => {
  return `${GetProjectPathForApi(organizationId, projectId)}/counts/pages`;
}

export const GetPageCount = (organizationId, projectId) => {
  return api.get(getPageCountPathForApi(organizationId, projectId))
    .then(resp => { 
      return resp.data
    });
}

const _chunkSize = 8 * 1024 * 1024;

export function GetUploadFilePromise(file, reservationUri, reservationParams, cancelToken, onUploadProgressHandler, onCompletedFileHandler) {
  const handleUploadProgressInternal = (e, completedSize) => onUploadProgressHandler(file.name, file.size, completedSize);
  handleUploadProgressInternal(null, 0);
  
  // Get an upload reservation
  // Will fail if Content-Type is not set with the value that will be used in the PUT
  return api.get(reservationUri,
    {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      params: reservationParams,
    })
    .then(async resp => {
      // As long as resp.data contains a SignedUrl property, we can ignore its actual type 
      let reservation = resp.data;
      // Initiate upload
      let initiateResult = await axios.put(reservation.SignedUrl, {},
        {
          headers: {
            "Content-Type": "application/octet-stream",
            // "Content-Length": "0", // This is now considered unsafe. Axios or the browser should automatically set this.
            "x-goog-resumable": "start",
          },
        }
      );
      let sessionUri = initiateResult.headers.location;
      let offset = 0;
      let processChunk = offset => new Promise((resolve, reject) => {
        try {
          let r = new FileReader();
          let chunk = file.slice(offset, _chunkSize + offset);
          r.onload = evt => {
            if (evt.target.error) {
              reject(evt.target.error);
            } else {
              resolve(evt.target.result);
            }
          }
          r.readAsArrayBuffer(chunk);
        } catch (err) { reject(err); }
      });


      // Read each chunk and upload synchronously
      while (offset < file.size || file.size === 0) {
        // Get chunk
        let binaryChunk = await processChunk(offset);
        let byteEnd = (file.size === 0) ? 0 : Math.min(offset+_chunkSize-1, file.size-1)
        // Upload chunk
        await axios.put(sessionUri, binaryChunk,
          { 
            headers: (file.size === 0) ? {} : { 
              // "Content-Length": `${file.size.toString()}`, // This is now considered unsafe. Axios or the browser should automatically set this.
              "Content-Range": `bytes ${offset.toString()}-${byteEnd.toString()}/${file.size.toString()}`,
            },
            onUploadProgress: e => handleUploadProgressInternal(e, byteEnd),
            cancelToken: cancelToken.token,
          }
        )
        .catch (err => {
          if (axios.isCancel(err)) {
            throw err;
          } 
            // A 308 is desired after uploading a chunk
          else if (err.response !== undefined && err.response.status !== 308) {
            throw err;
          }
        });
        // Stop here if this is a 0-byte file
        if (file.size === 0) {
          break
        }
        // Increment offset
        offset += binaryChunk.byteLength;
      } /* END CHUNK LOOP */

      // Success - Send revised upload reservation to server
      const { resp: completionResp, err: completionErr } = await onCompletedFileHandler(file, reservation);

      // Final progress update for file
      onUploadProgressHandler(file.name, file.size, file.size, true, completionResp, completionErr);

      if (completionErr) {
        return Promise.reject(completionErr);
      }
    });
}

export function UploadFiles(files, reservationUri, reservationParams, cancelToken, onUploadProgressHandler, 
  onCompletedFileHandler, onAlert, onApiError) {
  // files will be uploaded concurrently
  for (let i = 0; i < files.length; i++) {
    GetUploadFilePromise(files[i], reservationUri, reservationParams, cancelToken, onUploadProgressHandler, onCompletedFileHandler)
      // .then(() => { })
      .catch(err => {
        if (axios.isCancel(err)) {
          onAlert({
            Title: "Upload canceled",
            BodyText: "Item(s) have been canceled."
          });
        } else {
          onApiError(err);
        }
      });
  }
}

export function GetUploadBinaryPromise(binary, signedUrl, reservationUri, reservationParams, cancelToken, 
  onUploadProgressHandler, onCompletedFileHandler, onReservationObtained) {
  
  const handleUploadProgressInternal = (e, completedSize) => onUploadProgressHandler(binary.length, completedSize);
  handleUploadProgressInternal(null, 0);

  const handleUpload = async signedUrl => {
    // Initiate upload
    // As long as reservation contains a SignedUrl property, we can ignore its actual type 
    let initiateResult = await axios.put(signedUrl, {},
      {
        headers: {
          "Content-Type": "application/octet-stream",
          // "Content-Length": "0", // This is now considered unsafe. Axios or the browser should automatically set this.
          "x-goog-resumable": "start",
        },
      }
    );
    let sessionUri = initiateResult.headers.location;
    let offset = 0;
    // Read each chunk and upload synchronously
    while (offset < binary.length || binary.length === 0) {
      // Get chunk
      let binaryChunk = binary.slice(offset, _chunkSize + offset);
      let byteEnd = (binary.length === 0) ? 0 : Math.min(offset+_chunkSize-1, binary.length-1)
      // Upload chunk
      await axios.put(sessionUri, binaryChunk,
        { 
          headers: (binary.length === 0) ? {} : { 
            // "Content-Length": `${binary.length.toString()}`, // This is now considered unsafe. Axios or the browser should automatically set this.
            "Content-Range": `bytes ${offset.toString()}-${byteEnd.toString()}/${binary.length.toString()}`,
          },
          onUploadProgress: e => handleUploadProgressInternal(e, byteEnd),
          cancelToken: cancelToken.token,
        }
      )
      .catch (err => {
        if (axios.isCancel(err)) {
          throw err;
        } 
          // A 308 is desired after uploading a chunk
        else if (err.response !== undefined && err.response.status !== 308) {
          throw err;
        }
      });
      // Stop here if this is a 0-byte binary
      if (binary.length === 0) {
        break
      }
      // Increment offset
      offset += binaryChunk.byteLength;
    } /* END CHUNK LOOP */

    // Success - Call onCompletedFileHandler()
    const { resp: completionResp, err: completionErr } = await onCompletedFileHandler(binary);

    // Final progress update for binary
    onUploadProgressHandler(binary.length, binary.length, true, completionResp, completionErr);
  };
  
  if (signedUrl) {
    return new Promise(resolve => {
      resolve(handleUpload(signedUrl));
    });
  } else {
    // Get an upload reservation if we don't already have a signedUrl
    // Will fail if Content-Type is not set with the value that will be used in the PUT
    return api.get(reservationUri,
      {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        params: reservationParams,
      })
      .then(resp => {
        if (onReservationObtained) {
          onReservationObtained(resp.data);
        }

        handleUpload(resp.data.SignedUrl);
      });
  }
}

export function HandleFieldSelectionListFilter(organizationId, projectId, filter, handleApiError) {
  let extraParams = {
    filter,
    /*cursor: cursor,*/
  }; 
  return GetAllFieldsPromise(organizationId, projectId, extraParams)
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      return resp.data.Fields.map(f => { return ({ value: f.ID, label: f.Name }) });
    })
    .catch(err => handleApiError(err));
}

// Items passed through here must have an OrganizationID, ProjectID, and ID
export const ProcessApiCallsGroupedByProjectForItems = (items, getApiPromise) => {
  // Group by project
  let itemsByProject = [];
  items.forEach(i => {
    const itemsByProjectFinder = itemsByProject.filter(ibp => ibp.ProjectID === i.ProjectID);
    if (itemsByProjectFinder.length) {
      itemsByProjectFinder[0].Items.push(i);
    } else {
      itemsByProject.push({
        OrganizationID: i.OrganizationID,
        ProjectID: i.ProjectID,
        Items: [i],
      });
    }
  });
  let promises = [];
  itemsByProject.forEach(ibp => {
    promises.push(
      getApiPromise(ibp),
    );
  })
  return Promise.all(promises)
    .then(resp => {
      const allData = [].concat(...resp.map(r => r.data));
      return allData;
    });
}

export const HandleDownloadCaptureAgent = (onApiError, onDownloadStarted) => {
    api.get(GetFileDownloadsPathForApi(), { params: { fileName: "NucleusOne_CaptureAgent_Setup.exe" }})
      .then(resp => {
        if (resp.data) {
          const link = document.createElement('a');
          link.href = resp.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        if (onDownloadStarted) {
          onDownloadStarted();
        }
      })
      .catch(onApiError);
  }